import { makeStyles } from "@material-ui/core";
import car from "../../asset/images/car.png";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  imageSection: {
    width: "100%",
    height: "100%",
    "& img": {
      width: "100%",
    },
  },
  sectionOne: {
    backgroundRepeat: "no-repeat",
    color: "white",
    height: "90vh",
    backgroundSize: "cover",
    paddingTop:"80px",
    [theme.breakpoints.down("sm")]: {
    margin:"1.5em",
    height: "70vh",
    },
  },
  sectionOneText: {
    paddingTop:"1.5em",
    width:"50%",
    color:"#2E324B",
    margin: "auto 0",
    paddingLeft: "8.5em",
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0",
      width:"auto",
      padding:"0"
    },
  },
  sectionOneHeader: {
    fontSize: "4em",
    fontFamily: "calistItallics",
    marginBottom: "0.3em",
    lineHeight: "1em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em",
    },
  },
  sectionOneSentence: {
    "& p": {
      fontSize: "1.2em",
      fontFamily: "AvertaDemo",
      fontWeight: "500",
      width: "30em",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  sectionOneImage:{
    background:`url(${car})`,
    width:"40%",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"center",
    height:"75vh",
    [theme.breakpoints.down("sm")]: {
      display:"none"
    },
  },
  sectionTwo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#020820",
    alignItems: "center",
    paddingRight: "5em",
    display: "flex",
    justifyContent: "flex-end",
    height: "calc(100vh - 90px)",
    [theme.breakpoints.down("sm")]: {
      
    backgroundSize: "100%",
    height: "80vh",
      fontSize: "1em",
      padding: "0",
      alignItems:"normal",
      justifyContent: "flex-start",
    },
  },
  innerTwo: {
    position: "relative",
    width: "45%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "16em 1.5em 2em",
      width: "100%",
      top: "0",
      textAlign: "left",
    },
  },
  divider: {
    height: "0.1em",
    width: "20em",
    marginTop: "3.5em",
    marginBottom: "2em",
    position: "relative",
    backgroundColor: "#2E324B",
    
    [theme.breakpoints.down("sm")]: {
      left:"0",
      margin: "2em 0",
      order:"0"
    },

  },
  
  dividerTwo:{
    height:"0.25em",
    [theme.breakpoints.down("sm")]: {
      width:"25%",
     alignSelf:"start",
     margin:"0"
    },
  },
  fourthSecOne: {
    width: "15em",
    backgroundColor: "white",
    margin:"auto 0",
    [theme.breakpoints.down("sm")]: {
      marginTop:"2em",
      left:"0",
      width:"5em"
    },
  },
  fourthSecTwo: {
    width: "20em",
    backgroundColor: "white",
    alignSelf:"flex-end",
    order:"3",
    left:"-15em",
    marginTop:"2em",
    [theme.breakpoints.down("sm")]: {
      left:"0",
      width:"5em",
      order:"none"

    },
  },
  headerTwo: {
    fontSize: "3em",
    fontWeight: "500",
    lineHeight: "1em",
    fontFamily: "calistItallics",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  sentenceTwo: {
    fontSize: "1.2em",
    marginTop: "1.5em",
    fontFamily: "AvertaDemo",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      
    margin: "1em 0",
    },
  },
  sectionThree: {
    backgroundRepeat: "no-repeat",
    color: "#020820",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "5em",
    height:"100vh",
    display:"flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      padding: "0",
      justifyContent: "flex-start",
      backgroundSize: "cover",
    },
  },
  dividerOne:{
    height:"0.25em",
    [theme.breakpoints.down("sm")]: {
      width:"25%"
    }
  },
  innerThree: {
    position: "relative",
    width: "50%",
    marginLeft: "8em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "275px",
      width: "auto",
      margin: "0 1.5em ",
      backgroundSize: "100%",
    },
  },
  headerThree: {
    fontSize: "3em",
    lineHeight: "1",
    fontFamily: "calistItallics",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      lineHeight: "1em",
      backgroundSize: "100%",
    },
  },
  sentenceThree: {
    fontSize: "1.2em",
    marginTop: "1.5em",
    fontFamily: "AvertaDemo",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  sectionFour: {
    height: "90vh",
    backgroundColor: "#020820",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    "& #secondSquare": {
      backgroundColor:"#fff",
    },
    [theme.breakpoints.down("sm")]: {

      height:"auto",
    },
  },
  headerFour:{
    width:"100%",
    display:"flex",
    justifyContent:"center",
    [theme.breakpoints.down("sm")]: {
    },
  },
  words: {
    display:"flex",
    alignItems:"center",
    width:"100%",
    justifyContent:"center",
    "& p": {
      fontSize: "5em",
      lineHeight: "1.1em",
      position: "relative",
      fontFamily: "calistItallics",
      [theme.breakpoints.down("sm")]: {
        
      fontSize: "2em",
      },
    },
    "& p:nth-child(2)": {
      color:"#FADD98",
    },
    
    "& p:nth-child(3)": {
      alignSelf:"flex-end"
    },
    
    [theme.breakpoints.down("sm")]: {
      width:"90%",
    }
  },

  textFour: {
    width: "55%",
    
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sentenceFour: {
    fontSize: "1.25em",
    fontFamily: "AvertaDemo",
    fontWeight: "500",
    textAlign: "left",
    marginTop:"1.5em",
    [theme.breakpoints.down("sm")]: {
      
    fontSize: "1.05em",
    textAlign: "start",
    margin:"1.5em",

    },
  },

  sectionFive: {
    height:"90vh",
    color: "#020820",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "8.5em",
    display:"flex",
    paddingTop:"2em",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      fontSize: "1em",
    },
  },
  textFive: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      top:"0",
      padding: "0px 1.5em 2em",
      
    },
  },
  textFiveHeader: {
    fontSize: "3em",
    lineHeight: "1",
    fontFamily: "calistItallics",
    fontWeight: "500",
    marginBottom: "0.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
      lineHeight: "1em",
    },
  },
  textFiveSentence: {
    fontSize: "1.2em",
    fontFamily: "AvertaDemo",
    fontWeight: "500",
    width: "95%",
    marginBottom: "2.5em",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
      fontSize: "1em",
      width: "100%",
    },
  },
  square:{
    width:"1.2em",
    height:"1.2em",
    margin:"1.5em",
    backgroundColor:"#FADD98",
    
  },
  contactUs:{
    display:"flex",
  }
}));

export default useStyles;
