import React from "react";
import Box from "@material-ui/core/Box";
import { Paper, Typography } from "@material-ui/core";

import staircase from "../../asset/images/staircase.png";
import wheel from "../../asset/images/wheel.png";
import careerimg from "../../asset/images/careersimg.jpg";
import cultureimg from "../../asset/images/cultureimg.jpg";
import companyimg from "../../asset/images/companyimg.jpg";
import iceimage from "../../asset/images/iceimg.jpg";
import businessimage from "../../asset/images/businessimage.jpg";
import vacationimage from "../../asset/images/vacationimage.jpg";
import stockimage from "../../asset/images/stockimage.jpg";
import lunchimage from "../../asset/images/lunchimage.jpg";
import color from "../../asset/images/colour.jpg";
import { useStyles } from "./CareerStyle";

const Career = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.bluepart}>
        <div className={classes.firstmainright}>
          <img src={wheel} alt={"wheel"} />
        </div>
        <Box className={classes.firstmain}>
          <div className={classes.firstmainleft}>
            <Typography className={classes.firstmainbold}>
              {" Your Will Power Drives your Career Growth"}
            </Typography>
            <Typography className={classes.firstmainnormal}>
              {
                "Our staff make us standout, which is why we are constantly looking for more commited and passionate individuals with exceptional skills to come join our team"
              }
            </Typography>
          </div>
        </Box>
      </Paper>
      <Box className={classes.secondmain}>
        <div className={classes.secondmainleft}>
          <img src={staircase} alt={"staircase"} />
        </div>
        <div className={classes.secondmainright}>
          <Typography variant={"h3"}>
            {"We invest in you while you accelerate your Career"}
          </Typography>
          <Typography>
            {
              "We have built an incubator to mentor, nurture and build our staffs to be the best."
            }
          </Typography>
          <Typography>
            {
              "We have provided an enabling environment to maximize our staff potential, that is why we regard our company as an entrepreneurial business school."
            }
          </Typography>
          <Typography className={classes.secondmainbold}>
            {"JOIN OUR TEAM TODAY AND MAKE A DIFFERENCE"}
          </Typography>
        </div>
      </Box>
      <Box className={classes.thirdpart}>
        <div className={classes.thirdmain}>
          <div>
            <Typography className={classes.thirdmainbold}>
              {"Taking That Bold Step"}
            </Typography>
            <Typography className={classes.thirdmaintext}>
              {
                "Own that path, unlock those doors and seek opportunities to shape a better world. We can create that platform for you"
              }
            </Typography>
          </div>
        </div>
      </Box>
      <div className={classes.fourthmain}>
        <Box className={classes.imageSection}>
          <img src={careerimg} alt={"careerimage-cr"} />
          <Typography className={classes.cardtitle} variant={"h4"}>
            {"Career in Perspective"}
          </Typography>
          <Typography className={classes.cardText}>
            {
              "The best part of joining a global organization? The global opportunities on offer"
            }
          </Typography>
        </Box>

        <Box className={classes.imageSection}>
          <img src={cultureimg} alt={"section-cultureimage"} />
          <Typography className={classes.cardtitle} variant={"h4"}>
            {"Our Culture"}
          </Typography>
          <Typography className={classes.cardText}>
            {
              "Our people recognize the responsibilities they have to uphold  our values."
            }
          </Typography>
        </Box>

        <Box elevation={0} className={classes.imageSection}>
          <img src={companyimg} alt={"sct-companyimage"} />
          <Typography className={classes.cardtitle} variant={"h4"}>
            Our Company
          </Typography>
          <Typography className={classes.cardText}>
            {"Friendliness to support and reach a high performing team."}
          </Typography>
        </Box>

        <Box elevation={0} className={classes.imageSection}>
          <img src={iceimage} alt={"sct-iceimage"} />
          <Typography className={classes.cardtitle} variant={"h4"}>
            {"Ice Breaker Team"}
          </Typography>
          <Typography className={classes.cardText}>
            {
              "We will give you the support you need to reach your goals and fulfil your potentials."
            }
          </Typography>
        </Box>
      </div>
      <Box className={classes.fifthpart}>
        <div className={classes.fifthmain}>
          <div className={classes.fifthleft}>
            <img src={businessimage} alt={"sct-businessimage"} />
            <img src={stockimage} alt={"sct-stockimage"} />
            <img src={lunchimage} alt={"lunchimage"} />
            <img src={vacationimage} alt={"sct-vacationimage"} />
          </div>
          <div className={classes.fifthright}>
            <img src={color} alt={"color"} className={classes.fifthrightImg} />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Career;
