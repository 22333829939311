export const jobRoles = [
  {
    role: "Analyst Strategy Manager",
    id: 415265093,
    description: `He D.`,
    qualifications: `Sifax, e`,
  },
  {
    role: "React Frontend Developer",
    id: 13098201,
    description: `USA.`,
    qualifications: `InD.`,
  },
  {
    role: "Office Administrator",
    id: 10309821,
    description: `This`,
    qualifications: `In.`,
  },
  {
    role: "Project Manager",
    id: 13109820,
    description: `DSr.`,
    qualifications: `Juditn.`,
  },
  {
    role: "IT Support",
    id: 31109820,
    description: `In .`,
    qualifications: `Kes.`,
  },
  {
    role: "Application Developer - Full Stack",
    id: 30009820,
    description: `HeHF `,
    qualifications: `HeOPIP,`,
  },
];
