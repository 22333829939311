import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import copy from "../../asset/images/copy.png";
import { useStyles } from "./ContactUsStyle";
import emailjs from "emailjs-com";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Button, Divider, TextField, Typography } from "@material-ui/core";
import { ThankYou } from "./ThankYou";

const borderTheme = createTheme({
  palette: {
    primary: {
      main: "#2E324B", //your color
    },
  },
});

const handleContactFormSubmit = (e) => {
  e.preventDefault()
  console.log('info@ecscl.com')
}

const ContactUs = () => {
  const [copyOne] = useState("+2348170002139");
  const [copyTwo] = useState("sales@ecscorpresources.com");
  const [copiedOne, setCopiedOne] = useState(false);
  const [copiedTwo, setCopiedTwo] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phoneNumber: "",
    msgTitle: "",
    msg: "",
  });

  const [helperText, setHelperText] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    msgTitle: "",
    msg: "",
  });
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const USER_ID = process.env.EMAIL_JS_USER_ID;
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        if (value.length < 3 || /\d/.test(value)) {
          setValid(false);
          setHelperText({ ...helperText, [name]: "Enter a valid name" });
        } else {
          setHelperText("");
          setValid(true);
        }
        break;
      case "email":
        const emailPattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!emailPattern.test(value)) {
          setHelperText({ ...helperText, [name]: "Enter a valid email" });
          setValid(false);
        } else {
          setHelperText("");
          setValid(true);
        }
        break;
      case "phoneNumber":
        if (value.length < 10 || isNaN(value)) {
          setHelperText({
            ...helperText,
            [name]: "Enter a valid phone number",
          });
        } else {
          setHelperText("");
        }
        break;
      case "msgTitle":
      case "msg":
        if (value.length < 3) {
          setHelperText({ ...helperText, [name]: "entry too short" });
          setValid(false);
        } else {
          setHelperText("");
          setValid(true);
        }
        break;
      default:
    }

    setFormData({ ...formData, [name]: value });
  };

  const copyToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClickOne = () => {
    copyToClipboard(copyOne)
      .then(() => {
        setCopiedOne(true);
        setTimeout(() => {
          setCopiedOne(false);
        }, 2000);
      })
      .catch((err) => console.log(err));
  };
  const handleCopyClickTwo = () => {
    copyToClipboard(copyTwo)
      .then(() => {
        setCopiedTwo(true);
        setTimeout(() => {
          setCopiedTwo(false);
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (valid) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID).then(
        (result) => {
          setSuccess(true);
        },
        (error) => {
          console.log("err", error.text);
        }
      );
    }
  };
  return (
    <Box className={classes.root}>
      {success && <ThankYou />}
      <Box className={classes.sectionOne}>
        <Typography className={`${classes.sectionOneText}`} variant="h4">
          We speak your local Language with Global experience
        </Typography>
      </Box>
      <Box className={`${classes.sectionTwo} ${classes.column}`}>
        <Box className={`${classes.innerSectionTwo} ${classes.row}`}>
          <Box className={`${classes.leftSide} ${classes.column}`}>
            <Box className={`${classes.leftSideTop}`}>
              <Typography className={`${classes.leftSideHeader}`}>
                General Inquiries
              </Typography>
            </Box>
            <Box>
              <Typography gutterBottom className={`${classes.leftSideText}`}>
                To speak with our team, Please call
              </Typography>
              <Box className={`${classes.row}`} alignItems="center">
                <Typography
                  className={`${classes.leftSideNum}`}
                  value={copyOne}
                >
                  +234 817 000 2139
                </Typography>
                <span
                  onClick={handleCopyClickOne}
                  className={`${classes.copyText}`}
                >
                  &nbsp; &nbsp;
                  <img src={copy} alt="copy" />
                </span>
                <span
                  style={{
                    fontSize: "0.8em",
                    display: `${copiedOne ? "block" : "none"}`,
                  }}
                >
                  &nbsp; &nbsp;Copied!
                </span>
              </Box>
              <Typography className={`${classes.leftSideText}`}>
                We are available to respond
              </Typography>
              <Typography className={`${classes.leftSideDate}`}>
                Mondays to Fridays, 9.00am - 6.00pm
              </Typography>
            </Box>
            <Divider
              className={`${classes.divider}`}
              orientation="horizontal"
            />
            <Box>
              <Typography className={`${classes.leftSideSubHead}`}>
                Request a Quote
              </Typography>
              <Typography className={`${classes.leftSideSentence}`}>
                {
                  " For more information about how our services can deliver value, please send your queries to"
                }
              </Typography>
              <Box className={`${classes.row}`} alignItems="center">
                <Typography
                  className={`${classes.leftSideMail}`}
                  value={copyTwo}
                >
                  sales@ecscl.com
                </Typography>
                <span
                  onClick={handleCopyClickTwo}
                  className={`${classes.copyText}`}
                >
                  &nbsp; &nbsp;
                  <img src={copy} alt="copy" />
                </span>
                <span
                  style={{
                    fontSize: "0.8em",
                    display: `${copiedTwo ? "block" : "none"}`,
                  }}
                >
                  &nbsp; &nbsp;Copied!
                </span>
              </Box>
            </Box>
          </Box>
          <form
            className={`${classes.rightSide} ${classes.column}`}
            onSubmit={sendEmail}
          >
            <Typography className={`${classes.formHeader}`}>
              Send us a Mail
            </Typography>
            <ThemeProvider theme={borderTheme}>
              <TextField
                className={`${classes.textField}`}
                label="Name"
                variant="outlined"
                helperText={helperText["name"]}
                name="name"
                size="small"
                value={formData["name"]}
                onChange={handleChange}
                required
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
              <TextField
                className={`${classes.textField}`}
                label="Company"
                variant="outlined"
                name="company"
                size="small"
                value={formData["company"]}
                onChange={handleChange}
              />
              <TextField
                className={`${classes.textField}`}
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                size="small"
                helperText={helperText["email"]}
                value={formData["email"]}
                onChange={handleChange}
                required
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
              <TextField
                className={`${classes.textField}`}
                label="Phone No"
                variant="outlined"
                helperText={helperText["phoneNumber"]}
                name="phoneNumber"
                size="small"
                value={formData["phoneNumber"]}
                onChange={handleChange}
              />

              <TextField
                className={`${classes.textField}`}
                label="Message Title"
                variant="outlined"
                name="msgTitle"
                size="small"
                helperText={helperText["msgTitle"]}
                value={formData["msgTitle"]}
                onChange={handleChange}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                required
              />
              <TextField
                className={`${classes.textField}`}
                label="Message"
                variant="outlined"
                minRows={5}
                name="msg"
                multiline
                size="medium"
                helperText={helperText["msg"]}
                value={formData["msg"]}
                onChange={handleChange}
                required
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </ThemeProvider>
            <Button
              type={"submit"}
              className={`${classes.formButton}`}
              variant="contained"
              onClick={handleContactFormSubmit}
            >
              Submit Message
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
