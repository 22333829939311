import { Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {useStyles} from './NotFoundStyles'

export const NotFound = ()=>{
    const classes = useStyles()
    return(
    <section className={classes.page404}>
			        <Typography variant={"h1"}>404</Typography>
		            <Box className={classes.four_zero_four_bg} style={{backgroundRepeat:"no-repeat",backgroundSize:"contain",backgroundPosition:"center"}}>
		            </Box>
                    <Box className={classes.content_box}>
		                <Typography variant={"h2"}>{"Look like you're lost"}</Typography>
                        <Typography>{"The page you are looking for is not available!"}</Typography>
                        <Link className={classes.link404} to={"/"}>{"Go to Home"}</Link>
                    </Box>
    </section>)
}