import { makeStyles } from "@material-ui/core";

import ripples from "../../asset/images/ripples.png";

export const useStyles = makeStyles((themes) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },

  sectionOne: {
    color: "white",
    backgroundImage: `url(${ripples})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#2E324B",
    height: "75vh",
    alignItems: "center",
  },
  topHeader: {
    fontSize: "4em",
    lineHeight: "1em",
    marginBottom: "0.5em",
    fontFamily: "calistItallics",
    [themes.breakpoints.down("sm")]: {
      fontSize: "2.75em",
      marginRight: "2em",
      marginTop: "2em",
    },
  },
  topTextArea: {
    width: "45%",
    marginLeft: "7%",
    marginTop: "8%",
    [themes.breakpoints.down("sm")]: {
      width: "auto",
      margin: "auto 1.5em",
    },
  },
  topSentence: {
    fontSize: "1.25em",
    fontFamily: "AvertaDemo",
    width: "100%",
    [themes.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  queryImage: {
    position: "fixed",
    right: "0%",
    width: "4.5%",
    height: "8%",
    top: "26%",
    padding: "0.4em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: "1",
    border: "2px solid whitesmoke",
    borderRight: "0px",
  },

  sectionTwo: {
    backgroundColor: "#2E324B",
    height: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [themes.breakpoints.down("sm")]: {
      height: "7.5em",
      padding:"0em 1em",
      width:"auto"
    },
  },
  iconContainer: {
    display: "flex",
  },
  boxitem: {
    padding: "0 1.2em",
    textAlign: "center",
    [themes.breakpoints.down("sm")]: {
      padding:"0"
    },
  },
  imgIcon: {
    width: "100px",
    verticalAlign: "middle",
  },
  imgText: {
    color: "#d9d9dd",
    fontSize: "1.2em",
    fontFamily: "AvertaDemo",
    marginLeft: "-20px",
    [themes.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "auto",
      fontSize: "0.85em",
    },
  },

  sectionThree: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [themes.breakpoints.down("sm")]: {},
  },
  pictureArea: {
    marginTop: "4em",
    width: "80%",
    justifyContent: "center",
    gap: "40px",
    [themes.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "1em",
      width: "100%",
    },
  },
  pictureTextBox: {
    color: "#2E324B",
    width: "300px",
    [themes.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
    },
  },
  pictureHeader: {
    margin: "0.5em 0",
    fontWeight: "500",
    fontFamily: "AvertaDemo",
    fontSize: "1.5em",
    [themes.breakpoints.down("sm")]: {
      marginTop: ".3em",
      fontWeight: "800",
    },
  },
  pictureSentence: {
    fontFamily: "Opensanslight",
    fontWeight: "700",
  },
  pictureBox: {
    height: "70%",
  },
  pictureImage: {
    width: "100%",
    height: "100%",
  },
  pictureText: {
    [themes.breakpoints.down("sm")]: {
      margin: ".3em .5em",
    },
  },
  dividerFirst: {
    height: "0.2em",
    width: "32%",
    margin: "3.5em 0",
    backgroundColor: "#063E63",
    [themes.breakpoints.down("sm")]: {
      margin: "2.3em 0 1em",
      width: "60%",
    },
  },
  businessText: {
    width: "52%",
    marginBottom: "2.5em",
    [themes.breakpoints.down("sm")]: {
      width: "auto",
      margin: "1.5em",
    },
  },
  businessHeader: {
    textAlign: "start",
    fontSize: "2.7em",
    fontWeight: "500",
    marginBottom: "0.5em",
    color: "#063E63",
    fontFamily: "calistItallics",
    [themes.breakpoints.down("sm")]: {
      textAlign: "start",
      fontSize: "2em",
    },
  },
  businessSentence: {
    fontSize: "1em",
    fontWeight: "700",
    color: "#2E324B",
    fontFamily: "Opensanslight",

    [themes.breakpoints.down("sm")]: {
      textAlign: "start",
      padding: "0",
      fontSize: "1em",
    },
  },
  businessFooterText: {
    textAlign: "center",
    fontFamily: "Opensanslight",
    fontSize: "1.4em",
    color: "#2E324B",
    marginTop: "0.5em",
    marginBottom: "2.5em",
    fontWeight: "900",
    [themes.breakpoints.down("sm")]: {
      textAlign: "start",
      fontSize: "1.6em",
      marginBottom: "1.5em",
    },
  },
  
  sectionFour: {
    padding: "5em 0",
    alignItems: "center",
    backgroundColor: "#2E324B",
    color: "white",
    [themes.breakpoints.down("sm")]: {
      padding: "1.5em",
    },
  },
  optimumHeader: {
    fontSize: "3.5em",
    fontFamily: "calistItallics",
    fontWeight: "500",
    [themes.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "2.4em",
    },
  },
  optimumText: {
    fontSize: "1.5em",
    padding: "1.2em 0",
    lineHeight: "1.1em",
    width: "30%",
    fontWeight: "100 ",
    textAlign: "center",
    "@media only screen and (max-device-width: 1300px)": {
      width: "49%",
    },
    [themes.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "1em",
      alignSelf: "start",
      fontWeight: "normal",
      lineHeight: "1.2em",
    },
  },
  optimumIcon: {
    marginTop: "1.5em",
  },
  pointed_out: {
    margin: "auto",
    borderTop: "50px solid #2E324B",
    borderLeft: "50px solid transparent",
    borderRight: "50px solid transparent",
    backgroundColor: "transparent",
    position: "relative",
    top: "-1px",
    width: "0",
  },

  sectionFive: {
    justifyContent: "center",
    alignItems: "center",
    margin: "5em 0",
    [themes.breakpoints.down("sm")]: {
      margin: "1.5em",
    },
  },
  symbolArea: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    width: "50%",
    margin: "auto",
    [themes.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      width: "100%",
    },
  },
  symbolSubArea: {
    justifyContent: "center",
    alignItems: "center",
    [themes.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  symbolImage: {
    marginRight: "1em",
  },
  symbolText: {
    marginLeft: "0.2em",
    color: "#2E324B",
    [themes.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  symbolHeader: {
    fontSize: "2.2em",
    color: "#2E324B",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    [themes.breakpoints.down("sm")]: {
      fontSize: "1.5em",
      marginBottom: "0.5em",
    },
  },
  symbolSentence: {
    maxWidth: "15em",
    fontSize: "1em",
    fontFamily: "Opensanslight",
    fontWeight: "700",
  },

  sectionSix: {
    padding: "5em 0",
    display: "flex",
    backgroundColor: "#FADD98",
    color: "#2E324B",
    [themes.breakpoints.down("sm")]: {
      margin: "0",
      padding: "0",
      flexDirection: "column-reverse",
    },
  },
  sectionSixB: {
    [themes.breakpoints.down("sm")]: {
      flexDirection: "column !important",
    },
  },
  innerSix: {
    alignItems: "flex-end",
    width: "50%",
    margin: "auto",
    [themes.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  allText: {
    maxWidth: "38.75em",
  },
  sixHeader: {
    fontSize: "3em",
    lineHeight: "1em",
    fontWeight: "500",
    position: "relative",
    marginBottom: "0.5em",
    fontFamily: "calistItallics",
    width: "75%",
    [themes.breakpoints.down("sm")]: {
      width: "auto",
      left: "0",
      margin: "1.5em",

      fontSize: "2em",
    },
  },
  sixText: {
    fontWeight: "700",
    fontFamily: "Opensanslight",
    fontSize: "1.25em",
    [themes.breakpoints.down("sm")]: {
      width: "auto",
      left: "0",
      margin: "1.5em",
      fontSize: "1em",
    },
  },

  imgContainer: {
    width: "40%",
    "& img": {
      width: "100%",
      height: "100%",
    },
    [themes.breakpoints.down("sm")]: {
      width: "90%",
      margin: "2em auto 1em",
    },
  },
  sectionEight: {
    color: "white",
    marginBottom: "0em",
    height: "26em",
  },
}));
