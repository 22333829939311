import { useContext, useEffect} from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import navlogo from "../asset/images/navlogo.png";
import { Collapsible } from "../component/NavCollapsible";
import Dropdown from "../component/Dropdown";
import { ThemeContext } from "../styles/themes";
import useCheckMobileScreen from './../utils/checkMobile'

const NavBar = () => {
  const {navTheme} = useContext(ThemeContext)

  const [theme,updateNavTheme] = useState()
  const [isActive,setIsActive] = useState(false)
 
  const [dropdown, setDropdown] = useState(false);

  const ismobile = useCheckMobileScreen()
  const handleMenu = () => {
    setIsActive(!isActive)
  };
  const handleMouseEnter = () => setDropdown(true);
  const handleMouseLeave = () => setDropdown(false);
  
  useEffect(() => {
    if(window.scrollY<80){
    updateNavTheme(navTheme?.light)
    }
    else{
      updateNavTheme(navTheme?.dark)
    }
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        updateNavTheme(navTheme.dark)
      }
      else{
         updateNavTheme(navTheme?.light)
      }
    };

    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  },[navTheme.light, navTheme.dark]);
 
 return (
    <nav className="navbar" style={{ background: theme?.background, color: theme?.foreground }} >
      <div className="nav-container">
        <div className={`hamburger ${isActive?"active":""}`} onClick={handleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <NavLink to={"/"} className="nav-logo">
          <img src={navlogo} alt={"navLogo"} className="navlogoImg"/>
        </NavLink>
        <ul className={`nav-menu ${isActive?"active":""}`}>
          <li className="nav-item" onClick={handleMenu}>
            <NavLink exact to="/featured-insight" className="nav-link" >
              {"Featured Insight"}
            </NavLink>
          </li>
         {
          !ismobile
          ? <li
              className="nav-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavLink exact to="/" className="nav-link">
                {"Functions"}
              </NavLink>

              {dropdown && <Dropdown/>}
            </li>
            : <Collapsible handleMenuClick={handleMenu}/>
          }
          <li className="nav-item" onClick={handleMenu}>
            <NavLink exact to="/career" className="nav-link">
              {"Careers"}
            </NavLink>
          </li>
          <li className="nav-item" onClick={handleMenu}>
            <NavLink exact to="/about" className="nav-link">
              {"About Us"}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default NavBar;
