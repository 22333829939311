import {List,ListItem,ListItemText} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {ExpandLess,ExpandMore} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { useState } from 'react';
import { MenuItems } from './MenuItems';
import { ListItemButton } from '@mui/material';
import { NavLink } from 'react-router-dom';

const data = {
  "documents": [
    {
      "Id": 1,
      "Name": "Functions",
      "Sheets": MenuItems
    },
  ]
}

const CustomizedListItem = ({doc, handleMenuClick})=>{
  const [open, setopen] = useState(true)
  const handleClick = ()=> {
    setopen(!open);
  }
  const handleSubClick = ()=> handleMenuClick();
  return (
    <div>
      <ListItem button key={doc.Id} onClick={handleClick}>
        <ListItemText primary={doc.Name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        key={doc.Sheets.Id}
        in={open}
        timeout='auto'
        unmountOnExit
      >
        <List component='li' disablePadding key={doc.Id}>
          {doc.Sheets.map(sheet => {
            return (
              <ListItemButton component={NavLink} to={sheet.path} sx={{ pl: 4}} key={sheet.Id} onClick={handleSubClick}>
                <ListItemText key={sheet.Id} primary={sheet.title} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
      <Divider />
    </div>
  )
}

export const Collapsible = ({ handleMenuClick }) =>{
  const docs = data.documents;
  return (
    <div>
      <List component='nav' aria-labelledby='nested-list-subheader'>
        {docs.map(doc => {
          return (
            <CustomizedListItem key={doc.Id} doc={doc} handleMenuClick={handleMenuClick}/>
          );
        })}
      </List>     
    </div>
  );
}