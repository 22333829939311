import React from "react";
import { Parallax } from "react-parallax";
import Box from "@material-ui/core/Box";
import { Divider, Typography } from "@material-ui/core";

import { IconStory } from "./iconStory";
import people from "../../asset/images/people.png";
import downarrow from "../../asset/images/downarrow.png";
import running from "../../asset/images/running.png";
import running2 from "../../asset/images/running2.png";
import useCheckMobileScreen from "../../utils/checkMobile";
import { GetInTouch } from "../../component/ContactUs";
import useStyles from "./customerStrategyStyle";
import "../../styles/fonts.css";

const CustomerSatisfaction = () => {
  const classes = useStyles();
  const isMobile = useCheckMobileScreen();
  const DownloadAndUnderline = () => {
    return (
      <div className={classes.effectright}>
        <img
          src={downarrow}
          alt={"downarrow-effect"}
          className={classes.effectimage}
        />
        <div className={classes.effectunderline}></div>
      </div>
    );
  };
  return (
    <section className={classes.customerSatisfaction}>
      <Box className={classes.firstmain}>
        <div>
          <Divider className={classes.underline} />
        </div>
        <div className={classes.market}>
          <div className={classes.marketbold}>
            <Typography>{"Market Intelligence"}</Typography>
            <Typography>{"& Insight"}</Typography>
          </div>
          <div className={classes.marketnormaldiv}>
            <Typography>
              {
                "We help create influential insights about your customers in order to identify"
              }
            </Typography>
            <Typography>
              {"attractive segments to focus on your go-to-market strategy"}
            </Typography>
          </div>
        </div>

        <div className={classes.moreunderline}></div>
        <div className={classes.purplepart} />
      </Box>

      <Box className={classes.secondMain}>
        <Box className={classes.customer}>
          <Typography className={"customerbold"}>
            {"Customer innovation driven through insight"}
          </Typography>

          <Typography className={"customernormal"}>
            {
              "Giving insight to your customers DNA to enable you segment correctly and allocate resources more efficiently for a workable marketing plan."
            }
          </Typography>
        </Box>

        <Box className={classes.iconmain}>
          <div className={`${classes.row} `}>
            <IconStory />
          </div>
        </Box>
      </Box>

      <Parallax bgImage={people} strength={800}>
        <Box className={classes.specialeffect}>
          <div className={classes.texttop}>
            <div className={classes.effectleft}>
              <Typography>
                <span className={classes.bigtext}>Selling</span>
              </Typography>
              <Typography>
                <span className={classes.smalltext}>your</span>
                <span className={classes.bigtext}>brand</span>
                <span className={classes.smalltext}>correctly</span>
              </Typography>
              <Typography>
                <span className={classes.smalltext}>necessitates</span>
                <span className={classes.bigtext}>incessant</span>
              </Typography>
              <Typography>
                <span className={classes.bigtext}>Growth</span>
              </Typography>
            </div>
            {!isMobile && <DownloadAndUnderline />}
          </div>
          <div className={classes.effectdown}>
            <Typography>
              {
                "The touch point for business success is customer satisfaction through viable pricing option that suits customer need, prioritizes value preposition and continous change to meet customers demand."
              }
            </Typography>
          </div>
          {isMobile && <DownloadAndUnderline />}
        </Box>
      </Parallax>

      <Box>
        <div className={classes.fourthSection}>
          <Box className={classes.youContainer}>
            <Typography>You </Typography>
            <div className={classes.cartoonunderline} />
          </Box>
          <div className={classes.cartoon}>
            <Box className={classes.cartoonImg} id={"cartoonImg1"}>
              <img src={running} alt={"running-cartoon"} />
            </Box>
            <Box className={classes.cartoontext}>
              <Typography>
                {
                  "A good customer satisfaction experience is shared among few people, but a bad experience travels at the speed of light. Timely customer satisfaction elicits business growth"
                }
              </Typography>
            </Box>
            <Box className={classes.cartoonImg} id={"cartoonImg2"}>
              <img src={running2} alt={"running-cartoon"} />
            </Box>

            <Box className={classes.quality}>
              <div className={classes.qualityunderline}></div>
              <Typography className={classes.qualitytext}>
                Quality Customer Review
              </Typography>
            </Box>
          </div>
        </div>
        <Box className={classes.tennisball}>
          <div className={classes.contentDiv}>
            <Typography variant={"h4"}>
                {"Collaborate meet growth"}
            </Typography>
            <Typography>
              {
                "If you must remain in business, you need to find diverse ways to grow. We can help you with insight on how best you can use your available resources to achieve the best result"
              }
            </Typography>
          </div>
          <GetInTouch />
        </Box>
      </Box>
    </section>
  );
};

export default CustomerSatisfaction;
