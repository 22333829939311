import React from "react";
import { Divider, Typography, Box } from "@material-ui/core";
import useStyles from "./AppServiceStyle";
import planeTurbine from "../../asset/images/plane_turbine.png";
import planeTurbineMobile from "../../asset/images/planeTurbineMobile.png";
import globe_tech from "../../asset/images/globe_tech.png";
import globeTechMobile from "../../asset/images/globeTechMobile.png";
import butterfly from "../../asset/images/butterfly.png";
import butterflyMobile from "../../asset/images/butterflyMobile.png";
import useCheckMobileScreen from "../../utils/checkMobile";
import { Contactus } from "../../component/ContactUs";

const Technology = () => {
  const classes = useStyles();
  const isMobile = useCheckMobileScreen();
  return (
    <>
      <section
        className={`${classes.sectionOne} ${classes.section}`}
      >
        <Box className={`${classes.sectionOneText} ${classes.column}`}>
          <Typography className={`${classes.sectionOneHeader}`}>
            {" Driving the Future of Technology"}
          </Typography>
          <Box className={`${classes.sectionOneSentence}`}>
            <Typography>
              {"We use technology to solve most critical business issues. As the world's paradigm shifts to new technologies, we thrill our customers with specific custom software with robust performance."}
            </Typography>
          </Box>
          <Divider
            className={`${classes.divider} ${classes.dividerOne}`}
            orientation="horizontal"
          />
        </Box>
        <Box className={`${classes.sectionOneImage}`}>

        </Box>
      </section>
      <Box
        className={`${classes.sectionTwo}`}
        style={{
          backgroundImage: `url(${!isMobile ? globe_tech : globeTechMobile})`,
        }}
      >
        <Box className={classes.innerTwo}>
          <Divider className={`${classes.divider} ${classes.dividerTwo}`} orientation="horizontal" />
          <Typography className={`${classes.headerTwo}`}>
            {"Delivering service through Innovation"}
          </Typography>
          <Typography className={`${classes.sentenceTwo}`}>
            {
              "We unveil new methods to deliver service whilst enhancing organisational flexibility. We use technology to solve most critical business issues."
            }
          </Typography>
        </Box>
      </Box>
      <Box
        className={`${classes.sectionThree}`}
        style={{
          backgroundImage: `url(${!isMobile ? butterfly : butterflyMobile})`,
        }}
      >
        <Box className={classes.innerThree}>
          <Typography className={`${classes.headerThree}`}>
            {"Innovative insight for technology solution"}
          </Typography>
          <Typography className={`${classes.sentenceThree}`}>
            {
              "We utilize our creative space to design a tailored technology solution to better align our client business strategy and ensure the right resources are available on time. We are the best at new application development, modernization, maintenance all through development life cycle."
            }
          </Typography>
          <Typography className={`${classes.sentenceThree}`}>{"We operate at the intersection of innovation and transformation using agile approach to deal with all application complexities."}
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.sectionFour} ${classes.column}`}>
        <Box  className={`${classes.headerFour}`}>
          <Box className={classes.square}/>
          <Divider className={`${classes.divider} ${classes.fourthSecOne}`}
            orientation="horizontal"
          />
          <Box className={classes.square} id="secondSquare"/>
          <Divider className={`${classes.divider} ${classes.fourthSecOne}`}
            orientation="horizontal"
          />
          <Box className={classes.square}/>
          </Box>
          <div className={classes.words}>
            <Typography>{"Innovate"}</Typography>
            <Typography id="transform">{"Transform"}</Typography>
            <Typography>{"Perform"}</Typography>
          </div>
        
        <Box className={`${classes.textFour}`}>
          <Typography className={`${classes.sentenceFour}`}>
            {
              "We take our customers through this process to create better value for their software need. We proffer world class solution that delivers result, our process and methodology are simplified and cost effective."
            }
          </Typography>
        </Box>
      </Box> 
      <Box
        className={`${classes.sectionFive} `}
        style={{
          backgroundImage: `url(${
            !isMobile ? planeTurbine : planeTurbineMobile
          })`,
        }}
      >
        <Box className={`${classes.textFive}`}>
          <Typography className={`${classes.textFiveHeader}`}>
            {"Improved Productivity Always"}
          </Typography>
          <Typography className={`${classes.textFiveSentence}`}>
            {
              " We build our software in modules so that the solutions can be scalable over time. We also build a business case after the architecture to show reliability and immediate cost savings."
            }
          </Typography>
            <div  className={`${classes.contactUs}`} >
          <Contactus bgColor={"#2e324b"} color={"#fff"}/>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Technology;
