import { makeStyles } from "@material-ui/core";
import ball from "../../asset/images/ball.png";
export default makeStyles((theme) => ({
  customerSatisfaction: {
    margin: "0",
    padding: "0",
    backgroundColor: "#fafafa",
  },
  firstmain: {
    width: "100%",
    height: "100vh",
    marginTop: "90px",
    alignContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    },
  },
  underline: {
    height: "0.35rem",
    width: "5rem",
    margin: "auto",
    position: "relative",
    backgroundColor: "#2E324B",
  },
  blankspace: {
    height: "1.2em",
    margin: "0px",
    backgroundColor: "#fafafa",
  },

  market: {
    color: "#2E324B",
    [theme.breakpoints.down("sm")]: {},
  },
  marketbold: {
    "& p": {
      fontFamily: "geometric",
      fontSize: "5em",
      fontWeight: "bolder",
      lineHeight: "1",

      [theme.breakpoints.down("sm")]: {
        fontSize: "3em",
      },
    },
  },
  marketnormaldiv: {
    marginTop: "2em",
    padding: "1.2em",
    "& p": {
      fontFamily: "AvertaDemo",
      fontWeight: "500",
      fontSize: "1.4em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1em",
      },
    },
  },
  moreunderline: {
    height: "0.35rem",
    width: "10rem",
    margin: "0em auto 3em",

    backgroundColor: "#2E324B",
  },
  purplepart: {
    backgroundColor: "#2E324B",
    height: "6em",
    width: "100%",
  },

  // second section
  secondMain: {
    margin: "0 auto",
    alignContent: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0em 1.2em",
    },
  },
  customer: {
    width: "40em",
    margin: "auto",
    textAlign: "center",
    "& p.customerbold": {
      fontSize: "3em",
      lineHeight: "1",
      fontFamily: "calistItallics",
      padding:"0.5em 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.75em",
        paddingBottom: "1em",
      },
    },

    "& p.customernormal": {
      fontSize: "1.05em",
      fontFamily: "opensanslight",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "start",
    },
  },

  row: {
    display: "flex",
    justifyContent: "space-evenly",
    alignContent: "center",
    flexDirection: "row",
    margin: "2em auto",
    width: "60%",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  //third
  specialeffect: {
    fontFamily: "bellMTBold",
    padding: "3em 10em",
    [theme.breakpoints.down("sm")]: {
      padding: "1.2em",
    },
  },

  texttop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignContent: "center",
    margin: "1em 0",
  },
  effectleft: {
    "& p": {
      fontFamily: "bellMTBold",
      lineHeight: "4.5em",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "2.2em",
      },
    },
    "& img": {
      width: "81px",
      height: "81px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bigtext: {
    fontSize: "6em",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75em",
    },
  },
  smalltext: {
    fontSize: "3em",

    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  effectright: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      "& img": {
        width: "40px",
      },
    },
  },
  effectunderline: {
    height: "0.25rem",
    width: "8rem",
    background: "black",
    margin: "2em 0",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      margin: "1em 0",
    },
  },
  //4th part

  effectdown: {
    width: "100%",
    textAlign: "right",
    fontFamily: "Opensanslight",
    display: "flex",
    justifyContent: "flex-end",
    "& p": {
      width: "25em",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },

  fourthSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "4em",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
    },
  },
  youContainer: {
    width: "100%",
    display: "flex",
    "& p": {
      fontSize: "5em",
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cartoonunderline: {
    height: "0.5rem",
    width: "8rem",
    background: "black",
    marginTop: "6em",
  },

  cartoon: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  "& #cartoonImg1": {
    [theme.breakpoints.down("sm")]: {
      order: "1",
    },
  },
  "& #cartoonImg2": {
    [theme.breakpoints.down("sm")]: {
      order: "2",
    },
  },
  cartoonImg: {
    width: "373px",
    height: "302px",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "150px",
        height: "150px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px",
    },
  },

  cartoontext: {
    width: "425px",
    display: "flex",
    paddingBottom: "4em",
    "& p": {
      alignSelf: "flex-end",
      textAlign: "center",
      fontSize: "1.2em",
      fontWeight: "500",

      [theme.breakpoints.down("sm")]: {
        fontSize: "1em",
        textIndent: "15px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: "4",
      padding: "0 1em",
    },
  },

  quality: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& p": {
      fontSize: "3.5em",
      fontWeight: "bold",

      [theme.breakpoints.down("sm")]: {
        fontSize: "1.6em",
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: "3",

      justifyContent: "center",
    },
  },
  qualityunderline: {
    height: "0.5rem",
    width: "10rem",
    background: "black",
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  tennisball: {
    height: "100vh",
    backgroundImage: `url(${ball})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      // height: "45vh",
    },
  },
  contentDiv: {
    backgroundColor:"black",
    margin: "auto",
    width: "50%",
    color: "white",
    textAlign: "center",
    padding: "10px",
    opacity: "0.75",
    "& h4": {
    fontFamily: 'calistItallics',
      fontSize: "4em",
      lineHeight: "0.7",
      padding: "0.1em 0",
      fontWeight:"500",
 
    },
    "& p": {
      padding: "1em 0",
      fontFamily: 'calistItallics',
      width: "20em",
      margin: "auto",
      fontSize:"1.5em",
      lineHeight:"1",
      textAlign:"start"
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      "& p": {
        width: "100%",
        margin: "auto",
      },
    },
  },
}));
