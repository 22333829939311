import React from "react";
import { Typography, Box, Divider } from "@material-ui/core";

import square from "../../asset/images/square.jpg";
import yellowimage from "../../asset/images/yellowimage.png";
import { useStyles } from "./LearningStyle";

const Learning = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.heropart}>
        <Box className={classes.heromain}>
          <div className={classes.heroleft}>
            <Divider className={classes.heroline} />
            <Typography className={classes.herobig}>
              {"Efficiency through"}
              <span className={classes.herobigger}>{" Mentorship"}</span>
            </Typography>
            <Typography className={classes.herotext}>
              {
                "Increasing employee experience and knowledge through mentorship will result in productivity"
              }
            </Typography>
          </div>
          <div className={classes.heroright}></div>
        </Box>
      </Box>
      <Box className={classes.whitemain}>
        <div className={classes.whiteleft}>
          <div className={classes.textContent}>
            <Typography variant={"h3"}>{"Extraordinary"}</Typography>
            <Typography>{"Result driven by Innovation"}</Typography>
          </div>
          <img src={square} alt={"square"} className={classes.squareImg} />
        </div>
        <div className={classes.whiteright}>
          <Typography className={classes.segoesctext}>
            {"Employee Skills"}<span> {"∝"}</span> {"Competency Improvement"}
          </Typography>
          <div className={classes.whitepartline}></div>
          <Typography className={classes.whitefirst}>
            {
              "A development program will help bring your employees to a higher level of similar skills and knowledge. This helps reduce any weak link and toxic employee within the organisation."
            }
          </Typography>
        </div>
      </Box>
      <Box className={classes.yellowpart}>
        <div className={classes.yellowmain}>
          <div className={classes.addressline}></div>
          <Typography className={classes.addressbold}>
            {"Let us address your critical business priorities"}
          </Typography>
          <Typography className={classes.normaldivtest}>
            {
              "Organizations that adopt our mentorship program are best positioned to benefit from market changes."
            }
          </Typography>
          <Typography className={classes.benefittext}>
            {"The benefits are immense as it stands to boost:"}
          </Typography>
          <div className={classes.iconpart}>
            {[
              "Competence",
              "Interpersonal Skill",
              "Creativity",
              "Versatility",
              "Efficiency",
              "Confidence",
              "Knowledge",
              "Performance",
            ].map((i) => (
              <div className={classes.iconitem} key={i}>
                <div className={classes.item1}>
                  <img src={yellowimage} alt={"yellowimage"} />
                  <span className={classes.icontext}>{i}</span>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.undericonline}></div>
        </div>
      </Box>
      <Box className={classes.mentorship}>
        <div className={classes.mentorshipmain}>
          <Typography className={classes.mentorshipbold}>
            {"Use the right mentorship partner"}
          </Typography>
          <Typography className={classes.mentorshipnormal}>
            {
              "Business Mentorship transfer skills needed dynamic change management. The Organization can then be positioned to benefit from people & process."
            }
          </Typography>
        </div>
      </Box>
      <Box className={classes.pinkpart}>
        <div className={classes.pinkmain}>
          <Box className={classes.pinkleft}>{/* 
            <Typography className={classes.pinkleftnormal}>
              
            </Typography> */}

            <div className={classes.pinkleftdown}>
              <Typography className={classes.pinkleftnormal}>
              {
                  "For enquiries on our business mentorship programme, kindly send an email to"
                }
              </Typography>
              <Box>
                <Typography className={classes.pinkleftbold}>
                  {"sales@ecscorpresources.com"}
                </Typography>
                <Typography className={classes.pinkleftnormal}>
                  {"We are availble to respond"}
                </Typography>
                <Typography className={classes.pinkleftbold}>
                  {" Mondays to Fridays, 9.00am-6.00pm"}
                </Typography>
              </Box>
            </div>
          </Box>
          <Box className={classes.pinkright}>
            <Typography className={classes.pinkrightbold}>
              {" Try Our Business Mentorship Program Today"}
            </Typography>
            <Divider className={classes.whiteline} />
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Learning;
