import { makeStyles } from "@material-ui/core";
import pink from "../../asset/images/pink.png";
import pinkMobile from "../../asset/images/pinkMobile.png";
import pencilCup from "../../asset/images/pencilCup.jpg";

export const useStyles = makeStyles((theme) => ({
  heropart: {
    backgroundColor: "#f1f5f6",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
  },
  heromain: {
    color: "#2e324b",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "end",
      flexDirection: "column-reverse",
    },
  },
  heroline: {
    height: "0.35rem",
    width: "10rem",
    background: "#FADD98",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  heroleft: {
    width: "35em",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%",
    },
  },
  herobig: {
    fontSize: "3.5em",
    lineHeight: "0.85",
    fontFamily: "calistItallics",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  herobigger: {
    fontSize: "1.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
      lineHeight: "1",
    },
  },
  herotext: {
    marginTop: ".5em",
    marginLeft: "1.5em",
    fontSize: "1.2em",
    fontFamily: "AvertaDemo",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      width: "90%",
      margin: "20px auto",
    },
  },
  heroright: {
    width: "40%",
    height: "55%",
    margin: "auto 0",
    background: `url(${pencilCup})`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      margin: "100px auto 0",
      backgroundPositionX: "center",
      backgroundPositionY: "bottom",
      backgroundSize: "contain",
    },
  },
  whitemain: {
    height: "75vh",
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    flexDirection: "row",
    margin: "3em 0",
    paddingBottom: "0em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      margin: "0",
    },
  },
  whiteleft: {
    position: "relative",
    width: "30%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  textContent: {
    margin: "1em",
    textAlign: "initial",

    "& p": {
      fontSize: "29px",
      fontWeight: "300",
    },
    "& h3": {
      fontFamily: "calistItallics",
      fontSize: "50px",
      color: "#003349",
      lineHeight: "0.85",
      fontWeight: "500",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5em",
    },
  },
  squareImg: {
    width: "100%",
    height: "100%",
    margin: "20px auto 0",
    backgroundPositionX: "center",
    backgroundPositionY: "bottom",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      height: "100%",
      margin: "20px auto 0",
      backgroundPositionX: "center",
      backgroundPositionY: "bottom",
      backgroundSize: "contain",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      margin: "20px auto 0",
      backgroundPositionX: "center",
      backgroundPositionY: "bottom",
      backgroundSize: "contain",
    },
  },
  whiteright: {
    width:"40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "2em auto",
      textAlign: "start",
    },
  },
  imageBox: {
    marginTop: "0.5em",
    marginRight: "1em",
  },
  segoesctext: {
    textAlign: "center",
    fontSize: "3.5em",
    lineHeight: "1",
    margin: "0 auto",
    fontFamily: "calistItallics",
    '& span::before, span::after':{
      content:'"\\A"',
      whiteSpace:"pre"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75em",
      width: "auto",
    },
  },
  segoesctextImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "center",
  },
  whitepartline: {
    height: "0.35rem",
    width: "8rem",
    background: "black",
    margin: "1em auto 0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  yellowpart: {
    background: "#2E324B",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    padding: "3em 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  yellowmain: {
    width: "60%",
    textAlign: "center",
    paddingTop: "2em",
    margin: "0 auto",
    paddingBottom: "3em",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  addressdiv: {
    marginTop: "2.5em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2em",
    },
  },
  addressbold: {
    fontSize: "4em",
    lineHeight: "0.9",
    color: "#FADD98",
    fontFamily: "Bell Mt",
  },
  normaldiv: {
    marginTop: "2em",
  },
  normaldivtest: {
    color: "#fff",
    fontSize: "1.5em",
    padding: "40px 0",
    width: "32em",
    fontFamily: "AvertaDemo",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  benefittext: {
    color: "#FADD98",
    fontSize: "2em",
    paddingBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  whitefirst: {
    marginTop: "1.4em",
    fontSize: "1.25em",
    fontWeight: "500",
    width: "30em",
    fontFamily: "AvertaDemo",
    alignSelf: "end",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: "1.5em 0",
      fontSize: "1em",
    },
  },
  normaltext: {
    fontSize: "1.2em",
    fontWeight: "600",
  },
  addressline: {
    height: "0.25rem",
    width: "10rem",
    background: "#fff",
    margin: "2em auto",
  },
  iconpart: {
    display: "grid",
    color: "#fff",
    width: "100%",
    fontSize: "1.3em",
    fontFamily: "avertaDemo",
    rowGap: "1em",
    paddingLeft: "40px",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      justifyContent: "normal",
      fontSize: "1em",
      padding: "0",
      width: "80%",
      margin: "auto",
    },
  },
  iconitem: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    marginRight: "0 1em",
    "& img": {
      verticalAlign: "middle",
      marginRight: ".5em",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "100%",
      margin: ".25em 0",
    },
  },
  item1: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      flexBasis: "200px",
    },
  },
  undericonline: {
    height: "0.25rem",
    width: "19rem",
    background: "#fff",
    margin: "0 auto",
    marginTop: "5.5em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3em",
      width: "15rem",
    },
  },
  mentorship: {
    width: "100%",
    padding: "8em 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  mentorshipmain: {
    width: "50%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "5em auto",
      textAlign: "start",
    },
  },
  mentorshipbold: {
    fontSize: "3em",
    fontFamily: "calistItallics",
    marginBottom: ".7em",
    lineHeight: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em",
      width: "100%",
    },
  },
  mentorshipnormal: {
    fontSize: "1.2em",
    fontFamily: "AvertaDemo",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pinkpart: {
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${pinkMobile})`,
      height: "70vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "85vh",
    },
    backgroundImage: `url(${pink})`,
    height: "85vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
  },
  pinkmain: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "0",
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      padding: "0",
      width: "auto",
      height: "100%",
    },
  },
  pinkleft: {
    width: "30em",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      width: "90%",
      margin: "auto",
      marginBottom: "-32em",
      color: "black",
    },
  },
  pinkleftdown: {
    marginTop: "1.5em",
  },
  pinkleftnormal: {
    fontFamily: "AvertaDemo",
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  pinkleftbold: {
    fontSize: "1.5em",
    fontFamily: "calistItallics",
  },
  pinkright: {
    width: "30%",
    margin: "auto",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "2em auto 0",
    },
  },
  pinkrightbold: {
    fontSize: "3em",
    fontFamily: "calistItallics",
    color: "#fff",
    lineHeight: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.3em",
      lineHeight: "1.3",
    },
  },
  whiteline: {
    marginLeft: "50%",
    height: "0.25rem",
    background: "#fff",
    textAlign: "right",
    marginRight: "0",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
    },
  },
  touchlink: {
    display: "flex",
    textDecoration: "none",
    justifyContent: "right",
    alignItems: "center",
    color: "#fff",
    backgroundColor: "#000",
    width: "11em",
    height: "6vh",
    borderRadius: "40px",
    padding: "0.5em",
    marginTop: "4em",
  },
  touchlinktext: {
    fontSize: "1.5em",
  },
  toucharrow: {
    position: "relative",
    left: "1.2em",
  },
}));
