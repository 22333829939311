import { makeStyles, Typography } from "@material-ui/core";
const iconStoryData = [{
    title:"Market Insight",
    img:"tennisbat",
    body:"Creating a more functioning tool that drives the right marketing plan and explores the future to create a niche."
},{
    title:"Market Plan",
    img:"square",
    body:"Adopting the right road map for a sustainable business marketing plan "
},{
    title:"Loyalty Program",
    img:"crown",
    body:"Build a strong brand customer relationship where the customers have a sense of ownership."
},
{
    title:"Customer Experience",
    img:"star",
    body:"Walking through customer experiences simulation to develop strong connections with your brand."
}

]
const iStyles = makeStyles((theme) => ({
  iconpart: {
  display: "flex",
  flexDirection: "row",
  padding: "1em",
  width:"24em",
  [theme.breakpoints.down("sm")]: {
    marginTop:"0"
  },
},
iconimage: {
  maxHeight: "80px",
  paddingTop: "1em",
},
icontext: {
  paddingLeft: "2em",
},
iconbold:{
    fontSize:"1.2em",
    fontWeight:"bold"
}
}))

export const IconStory = () =>{
    const classes = iStyles()
    return(
        <>
        {
        iconStoryData.map(i=>{
        return(
            <div className={classes.iconpart}>
              <img
               src = {require(`./../../asset/images/${i.img}.png`).default}
                alt={i.img}
                className={classes.iconimage}
              />
              <div className={classes.icontext}>
                <Typography className={classes.iconbold}> {i.title}</Typography>
                <Typography>{i.body} </Typography>
              </div>
            </div>
        )
    })
}</>)
    
}