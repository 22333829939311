import { createTheme, makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  hero: {
    paddingTop: "90px",
    display: "flex",
    width: "100%",
    height: "calc(100vh - 90px)",
    backgroundColor: "#4158D0",
    backgroundImage:
      "linear-gradient(43deg, #2A3035 0%, #293242 46%, #344278 100%)",
  },
  bluemain: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "center",
    margin: "auto",
    color: "#fff",
    fontFamily: "Averta demo",
    "@media screen and (max-device-width:600px)": {
      padding: "0em 0em 7em 0em",
      flexDirection: "row",
    },
  },
  blueLeft: {
    fontFamily: "Opensans",
    width: "50%",
    margin: "auto",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heroText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "8em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  bluesustain: {
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
    paddingBottom: "1.4em",
    "& p": {
      fontFamily: "calistItallics",
      fontSize: "4rem",
      lineHeight: "1em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3em",
      },
    },
    "& #text1": {
      fontSize: "5em",
      animation: `$slideup 2s ease `,
      [theme.breakpoints.down("sm")]: {
        fontSize: "3em",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "7em",
      },
    },
    "& #text2": {
      animation: `$slideup 3s ease`,
      [theme.breakpoints.down("sm")]: {
        fontSize: "2em",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "5em",
      },
    },
  },
  bluestrike: {
    animation: `$slideup 3.5s ease`,
    width: "34em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "2em",
      paddingBottom: "2em",
    },
    "& p": {
      fontFamily: "AvertaDemo",
      fontSize: "1em",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1em",
        width: "80%",
        marginLeft: "1em",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "1.75em",
      },
    },
  },
  "@keyframes slideup": {
    "0%": { transform: "translateX(-250px)" },
    "100%": { transform: "translatex(0px)" },
  },
  "@keyframes slidein": {
    "0%": { transform: "translateX(250px)" },
    "100%": { transform: "translateX(0px)" },
  },
  "@keyframes slideongoing": {
    "0%": {
      visibility: "visible",
      transform: "translateX(250px)",
    },
    "16.67%": { transform: "translateX(0px)" },
    "33.33%": { transform: "translateX(-250px)" },
    "50%": { transform: "translateX(250px)" },
    "66.67%": { transform: "translateX(0px)" },
    "83.33%": { transform: "translateX(-250px)" },
    "100%": {
      visibility: "hidden",
      transform: "translateX(250px)",
    },
  },
  blueRight: {
    width: "50%",
    display: "flex",
    animation: `$slidein 3.5s ease`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "40vh",
      alignItems: "center",
      justifyContent: "center",
      animation: `$slideongoing 20.5s ease infinite alternate-reverse`,
    },
  },

  circleImageBox: {
    width: "174px",
    height: "174px",
    margin: "0 1.5em",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      gridColumn: "1",
      gridRow: "1",
      textAlign: "center",
      paddingLeft: "12px",
      paddingRight: "24px",
    },
    "& img": {
      gridColumn: "1",
      gridRow: "1",
      animation: `$rotate 4s linear 0s 2 alternate-reverse`,
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        animation: `$rotate 4s linear 0s infinite alternate-reverse`,
      },
    },
  },

  "@keyframes rotate": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },

  linktext: {
    fontFamily: Opensans,
  },
  blackpart: {
    fontFamily: "Opensans",
    backgroundColor: "#020820",
    height: "calc(100vh - 100px)",
    paddingBottom: "2em",
    width: "100%",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
  },
  blackmain: {
    fontFamily: "Opensans",
    paddingTop: "10em",
    paddingLeft: "8em",
    "@media only screen and (max-device-width: 600px)": {
      paddingLeft: "2em",
      paddingTop: "5em",
    },
  },
  performance: {
    fontFamily: "calistItallics",
    display: "flex",
    marginTop: "0.4em",
    textDecoration: "none",
    justifyContent: "left",
    alignItems: "center",
    paddingBottom: "2em",
    fontSize: "5em",
    color: "#fff",
    "@media only screen and (max-device-width: 600px)": {
      paddingLeft: "0em",
      fontSize: "2em",
    },
  },
  blacktext: {
    fontFamily: "BellMTBold",
    fontSize: "5em",
    lineHeight: "1",
    "@media only screen and (max-device-width: 600px)": {
      fontSize: "2em",
    },
  },
  blackarrow: {
    padding: "0em 1em",
    width: "100px",
    "@media only screen and (max-device-width: 600px)": {
      width: "50px",
    },
  },
  //continued here
  fourthSection: {
    margin: "5em 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  strengthpart: {
    paddingTop: "6em",
    paddingBottom: "2em",
    justifyContent: "center",
    alignItems: "center",
    display: "inline-grid",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      margin: "auto",
      padding: "2em",
    },
  },
  strengthpartLower: {
    padding: "2em",
    justifyContent: "center",
    display: "flex",
    "& #secondBox": {
      alignItems: "end",
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      margin: "1em",
      flexDirection: "column",
      padding: "0",
    },
  },
  textBox: {
    "& >p": {
      width: "78%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    width: "50%",
    display: "flex",
    flexDirection: "column",
    margin:"auto 0",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      width: "90% ",
      margin: "1em",
    },
  },
  strengthbold: {
    fontSize: "2em",
    fontWeight: "bold",
    fontFamily: "calistItallics",
    lineHeight: "1.1",
    "& span": {
      lineHeight: 1.25,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5em",
    },
  },
  strengthnormal: {
    width: "77%",
    "& p": {
      padding: "0.65em 0",
      fontSize: "1em",
      fontWeight: "500",

      fontFamily: "AvertaDemo",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.15em",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% ",
    },
  },
  strengthmain: {
    marginTop: "3em",
    marginBottom: "3em",
  },

  cardpictures: {
    fontFamily: "Opensans",
    padding: "4em 0",
    paddingBottom: "3em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "1em",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      padding: "0",
      width: "100%",
    },
  },
  imageSection: {
    display: "flex",
    flexDirection: "column",
    gap:"1em",
    margin: "0em 0.5em",
    textDecoration: "none",
    width: "276px",
    color: "#354054 !important",
    [theme.breakpoints.down("sm")]: {
      // display:'block',
      width: "100%",
      margin: "0",
    },
  },
  imageBag: {
    width: "100%",
    height: "43%",
  },
  imageSelf: {
    width: "100%",
    height: "100%",
  },
  secondBag: {
    marginTop: "0.5em",
    [theme.breakpoints.down("sm")]: {
      margin: "1em",
    },
  },
  cardHeader: {
    fontSize: "1.25em",
    fontWeight: "700",
    fontFamily: "Opensanslight",
    width: "100%",
  },
  cardText: {
    marginTop: "1em",
    fontWeight: "500",
    fontFamily: "AvertaDemo",
    fontSize: "1em",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },

  lowertext: {
    fontSize: "1.5em",
  },

  fifthSection: {
    display: "flex",
    flexDirection: "column",
  },
  helmetpart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    color: "#fff",
    padding: "4em 0",
    paddingLeft: "8.5em",
    backgroundColor: "#013B4F",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "0",
    },
  },
  helmetleft: {
    fontFamily: "BellMTBold",
    display: "flex",
    margin: "auto 0",
    flexDirection: "column",
    width: "37%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: "0",
      margin: "1.5em",

      alignItems: "flex-start",
    },
  },
  helmetbold: {
    color: "#ddddc2",
    fontFamily: "calistItallics",
    fontSize: "3.2em",
    lineHeight: "1",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
    },
  },
  helmetnormal: {
    paddingTop: "2em",
    "& p": {
      fontFamily: "AvertaDemo",
      fontSize: "1.1em",
    },
  },
  helmetright: {
    fontFamily: "OpensansBold",
    width: "50%",
    margin: "auto",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundImage: "none",
      zIndex: 0,
    },
  },
  market: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    justifyContent: "space-around",
    fontSize: "1.5em",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "0em",
      padding: "0em",
      top: "0",
    },
  },
  paragraph: {
    textAlign: "center",
    fontSize: "1em",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      bottom: "0",
    },
  },
  paratext: {
    fontSize: "1.5em",
    fontFamily: "OpensansBold",
    fontWeight: "700",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      left: "0",
      fontSize: "1em",
    },
  },
  paratext1: {
    fontSize: "1em",
    position: "relative",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  helmetImages: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logomain: {
    marginTop: "2em",
  },
  wrapper: {
    padding: " 1em 0",
    overflow: "hidden",
    "& img": {
      margin: " 15px 40px",
      color: " #eee",
      overflow: " hidden",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
}));

const Opensans = createTheme({
  typography: {
    fontFamily: ["Opensanslight"].join(","),
  },
});
