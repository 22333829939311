import React from "react";
import HomePage from "./pages/home";
import About from "./pages/about";
import Career from "./pages/career";
import DigitalCommerce from "./pages/digitalCommerce";
import BusinessAgility from "./pages/businessAgility";
import ContactUs from "./pages/contactus";
import Learning from "./pages/learning";
import ApplicationService from "./pages/applicationService";
import FeaturedInsight from "./pages/featuredInsight";
import CustomerSatisfaction from "./pages/customerStrategy";
import Jobs from "./pages/jobs";
import OtherServices from "./pages/OtherServices";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "./globals/Footer";
import { Query } from "./component/Query";
import { Header } from "./globals/Header";
import ScrollToTop from "./utils/ScrollToTop";
import "./styles/fonts.css";
import "./styles/navbar.css";
import "./styles/animation.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/themes";
import { NotFound } from "./pages/notFound/NotFound";
const App = () => {
  return (
    <>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop/>
        <Header />
        <main>
          <Query />
          <Switch>
            <Route path="/" exact component={HomePage}></Route>
            <Route path="/learning" exact component={Learning}></Route>
            <Route
              path="/digital-commerce"
              exact
              component={DigitalCommerce}
            ></Route>
            <Route
              path="/customer-strategy"
              component={CustomerSatisfaction}
            ></Route>
            <Route path="/business-agility" component={BusinessAgility}></Route>
            <Route path="/career" component={Career}></Route>
            <Route path="/contact-us" component={ContactUs}></Route>
            <Route path="/about" component={About}></Route>
            <Route
              path="/application-service"
              component={ApplicationService}
            ></Route>
            <Route path="/job-search" component={Jobs}></Route>
            <Route path="/featured-insight" component={FeaturedInsight}></Route>
            <Route path="/other-services" component={OtherServices}></Route>
            <Route component={NotFound} />
          </Switch>
        </main>
        <Footer />
      </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default App;
