import { useState } from "react";
import { AccordionItem } from "./AccordionItem";

const Accordion = ({ jobRoles }) => {
  const [activeIndex, setActiveIndex] = useState();
  const renderedjobRolesAndDetail = jobRoles.map((item, index) => {
    let showDetails = index === activeIndex ? "showDetails" : "";
    const ariaExpanded = index === activeIndex ? "true" : "false";

    return (
      <AccordionItem
        item={item}
        index={index}
        ariaExpanded={ariaExpanded}
        showDetails={showDetails}
        onClick={() => {
          if (index === activeIndex && showDetails === "showDetails") {
            setActiveIndex(-1);
          } else {
            setActiveIndex(index);
          }
        }}
      />
    );
  });

  return (
    <div>
      <dl className="jobroles__list">{renderedjobRolesAndDetail}</dl>
    </div>
  );
};

export default Accordion;
