import { makeStyles } from "@material-ui/styles";
import query from "../asset/images/query.png";
import { Link } from "react-router-dom";
export const Query = () => {
  const classes = makeStyles({
    queryImage: {
      top: "140px",
      right: "0",
      cursor: "pointer",
      width: "69px",
      padding: "10px 0px 0px 10px",
      background: "transparent",
      position: "fixed",
      zIndex: "2",
      border: "1px solid white",
      "& img": {
        width: "100%",
      },
    },
  })();
  return (
    <div className={classes.queryImage}>
      <Link to="/contact-us">
      <img src={query} alt="query" />
      </Link>
    </div>
  );
};
