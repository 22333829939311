import { useEffect } from "react";
import { Link } from "react-router-dom";
import { default as FastMarquee } from "react-fast-marquee";
import Box from "@material-ui/core/Box";
import { Paper, Typography } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { SolutionComponent } from "./Solution";
import { useStyles } from "./HomeStyles";
import { GetInContact } from "./GetInContact";
import useCheckMobileScreen from "../../utils/checkMobile";

import bigarrow from "../../asset/images/bigarrow.png";
import bulb from "../../asset/images/bulb.png";
import headphone from "../../asset/images/headphone.png";
import ic from "../../asset/images/ic.png";
import phone from "../../asset/images/phone.png";
import mtn from "../../asset/images/mtn.png";
import smile from "../../asset/images/smile.png";
import firstbank from "../../asset/images/firstbank.png";
import sifax from "../../asset/images/sifax.png";
import cargo from "../../asset/images/cargo.png";
import lagos from "../../asset/images/lagos.png";
import towers from "../../asset/images/towers.png";
import police from "../../asset/images/police.png";
import wapic from "../../asset/images/wapic.png";
import mansard from "../../asset/images/mansard.png";
import cdk from "../../asset/images/cdk.png";
import mainone from "../../asset/images/mainone.png";
import arche from "../../asset/images/arche.png";
import airtel from "../../asset/images/airtel.png";
import platform from "../../asset/images/platform.png";
import circle from "../../asset/images/circleone.png";
import circletwo from "../../asset/images/circletwo.png";
import circlethree from "../../asset/images/circlethree.png";
import helmetOne from "../../asset/images/helmetOne.png";
import helmetTwo from "../../asset/images/helmetTwo.png";

const HomePage = () => {
  const classes = useStyles();
  const isMobile = useCheckMobileScreen()
  const textbox1 = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 }, delay: "0" },
    hidden: { opacity: 0.3, x: -70 },
  };
  const mobiletextbox = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 }, delay: "0" },
    hidden: { opacity: 1, x: 0 },
  }
  const textbox2 = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 }, delay: "0" },
    hidden: { opacity: 0.3, x: 70 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();


  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    
    }
  }, [controls, inView]);
  return (
    <>
      <section className={classes.hero}>
        <div className={classes.bluemain}>
          <div className={classes.blueLeft}>
            <div className={classes.heroText}>
              <div className={classes.bluesustain}>
                <Typography id="text1">{"Sustaining"}</Typography>
                <Typography id="text2">{"Business Growth "}</Typography>
              </div>
              <div className={classes.bluestrike}>
                <Typography>
                  {
                    "We strike the right chord by exchanging ideas and connecting minds through partnerships and alliances to deliver strategic values"
                  }
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.blueRight}>
            <Box className={classes.circleImageBox}>
              <img
                src={circle}
                alt={"circle"}
                className={classes.circleImage}
              />
              <Typography>{"Insight"}</Typography>
            </Box>
            <Box className={classes.circleImageBox}>
              <img src={circletwo} alt={"circle"} />
              <Typography>{"Analysis"}</Typography>
            </Box>
            <Box className={classes.circleImageBox}>
              <img src={circlethree} alt={"circle"} />
              <Typography>{"Reporting"}</Typography>
            </Box>
          </div>
        </div>
      </section>
      <SolutionComponent />
      {/* enabling part */}
      <Box className={classes.blackpart}>
        <div className={classes.blackmain}>
          <Typography className={classes.blacktext}>
            {"Enabling operational"}
          </Typography>
          <Typography className={classes.blacktext} >
            {"tenacity for optimum"}
          </Typography>
          <Link to="/featured-insight" className={classes.performance}>
            <i>{"performance"}</i>
            <img
              src={bigarrow}
              className={classes.blackarrow}
              alt={"big-black-arrow"}
            />
          </Link>
        </div>
      </Box>

      <Box className={classes.fourthSection}>
        {/* strength part */}
        <Box className={classes.strengthpartLower}>
          <Box
            className={classes.textBox}
            component={motion.div}
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={!isMobile?textbox1:mobiletextbox}
          >
            <Typography className={`${classes.strengthbold}`}>
              {"Strengthen your efforts with collaboration"}
            </Typography>
            <div className={`${classes.strengthnormal} `}>
              <Typography>
                {
                  "Every company needs support especially when it comes to performance improvement. We can help you deliver your objective as our model lowers cost and drives efficiency."
                }
              </Typography>
            </div>
          </Box>

          <Box
            component={Link}
            to={{ pathname: "/other-services", state: { section: "enablingTech" } }}
            className={classes.imageSection}
          >
            <Box className={classes.imageBag}>
              <img
                className={classes.imageSelf}
                alt={"imageSelf"}
                src={ic}
              />
            </Box>
            <Box className={classes.secondBag}>
              <Typography className={classes.cardHeader}>
                {"Enabling Technology"}
              </Typography>
              <Typography className={classes.cardText}>
                {
                  "We review business concerns with deep evaluation tools, to forecast potential impact..."
                }
              </Typography>
            </Box>
          </Box>

          <Box
            component={Link}
            to={{ pathname: "/other-services", state: { section: "employeeEval" } }}
            elevation={0}
            className={classes.imageSection}
          >
            <Box className={classes.imageBag}>
              <img
                className={classes.imageSelf}
                alt={"imageSelf"}
                src={bulb}
              />
            </Box>
            <Box className={classes.secondBag}>
              <Typography className={classes.cardHeader}>
                Employee Evaluation
              </Typography>
              <Typography className={classes.cardText}>
                {
                  "We provide excellent human capital evaluation service that allows you better understand your..."
                }
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.strengthpartLower}>
          <Paper
            component={Link}
            to={{ pathname: "/other-services", state: { section: "OpsEfficiency" } }}
            elevation={0}
            className={classes.imageSection}
          >
            <Box className={classes.imageBag}>
              <img
                className={classes.imageSelf}
                src={phone}
                alt="toytrain"
              />
            </Box>
            <Box className={classes.secondBag}>
              <Typography className={classes.cardHeader}>
                {"Operation & Efficiency"}
              </Typography>
              <Typography className={classes.cardText}>
                {
                  "We create the highest level of efficiency possible through best business practices..."
                }
              </Typography>
            </Box>
          </Paper>

          <Paper
            component={Link}
            to={{ pathname: "/other-services", state: { section: "digitalComm" } }}
            className={classes.imageSection}
            elevation={0}
          >
            <Box className={classes.imageBag}>
              <img className={classes.imageSelf} src={headphone} alt="box" />
            </Box>
            <Box className={classes.secondBag}>
              <Typography className={classes.cardHeader}>
                Digital Commerce
              </Typography>
              <Typography className={classes.cardText}>
                {
                  "We capture your customers perception of your band and create new product using their insight..."
                }
              </Typography>
            </Box>
          </Paper>

          <motion.div
            className={classes.textBox}
            id={"secondBox"}
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={!isMobile?textbox2:mobiletextbox}
          >
            <Typography className={`${classes.strengthbold}`}>
              {"Your Business Is Our Business"}
            </Typography>
            <div className={`${classes.strengthnormal} `}>
              {/* <Typography>
                {
                  " Focusing on set goals while analyzing current challenges and processes have quick impact on business growth."
                }{" "}
              </Typography> */}
              <Typography>
                {
                  "Our dynamic team of management professionals and technology experts are available to proffer diverse solutions and innovations that resolve your business complexities."
                }
              </Typography>
            </div>
          </motion.div>
        </Box>
      </Box>

      <Box className={classes.fifthSection}>
        <Box className={classes.helmetpart}>
          <div className={classes.helmetleft}>
            <Typography className={classes.helmetbold}>
              {"An Adept Hub for Business Spotlight"}
            </Typography>
            <div className={classes.helmetnormal}>
              <Typography>
                {
                  "Using the best practices methodology to improve processes, we can help you cut wastage and stay ahead of competition. The focus is to help you deliver significant and measurable changes."
                }
              </Typography>
            </div>
          </div>
          <div className={classes.helmetright}>
            <div className={classes.helmetImages}>
              <img src={helmetTwo} alt={"helmetTwo"} />
              <span className={classes.paragraph}>VS</span>
              <img src={helmetOne} alt={"helmetOne"} />
            </div>
            <div className={`${classes.market} ${classes.paratext}`}>
              <span
              >
                {"Market Forces"}
              </span>
              <span
              >
                {"Market Insight"}
              </span>
            </div>
          </div>
        </Box>

        <GetInContact />
      </Box>
      <FastMarquee speed={40} gradient={true} className={classes.wrapper}>
        <img src={mtn} alt={"logo-mtn"} />
        <img src={smile} alt={"logo-smile"} />
        <img src={firstbank} alt={"logo-firstbank"} />
        <img src={sifax} alt={"logo-sifax"} />
        <img src={cargo} alt={"logo-cargo"} />
        <img src={lagos} alt={"logo-lagos"} />
        <img src={towers} alt={"logo-towers"} />
        <img src={police} alt={"logo-police"} />
        <img src={wapic} alt={"logo-wapic"} />
        <img src={mansard} alt={"logo-mansard"} />
        <img src={cdk} alt={"logo-cdk"} />
        <img src={mainone} alt={"logo-mainone"} />
        <img src={arche} alt={"logo-arche"} />
        <img src={airtel} alt={"logo-airtel"} />
        <img src={platform} alt={"logo-platform"} />
      </FastMarquee>
    </>
  );
};

export default HomePage;
