import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    page404:{
       height:"70vh",
       display:"flex",
       flexDirection:"column",
       justifyContent:"center",
       alignItems:"center",
       margin:"1em",
       marginTop:"100px"
    },content_box:{
        textAlign:"center"
             },
             link404:{		
                padding:" 10px 20px",
                background: "#2e324b",
                borderRadius:"5px",
                color:"#fff",
                margin: "20px 0",
                display: "inline-block",
                fontFamily:"avertaDemo",
                
            },
    four_zero_four_bg:{
        backgroundImage:`url(${'https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif'})}`,
        width:"100%",
        height:"100%",
    },
    

}))