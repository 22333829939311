
import { makeStyles } from "@material-ui/core";
import typewriter from "../../asset/images/typewriter.png";

export const useStyles = makeStyles({
    column: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    firstSection: {
      backgroundImage: `url(${typewriter})`,
      height: "34em",
      color: "#020820",
      justifyContent: "center",
    },
    textBoxFirst: {
      alignItems: "center",
      position: "relative",
      top: "15%",
    },
    sectionOneText: {
      fontSize: "3.2em",
      fontWeight: "700",
      width: "45%",
      lineHeight: "1em",
      position: "relative",
      left: "5%",
    },
    queryBox: {
      width: "100%",
      cursor: "pointer",
      background: "rgba(0, 0, 0, 0.3)",
      position: "relative",
      top: "-25%",
      alignItems: "flex-end",
    },
    queryPic: {
      border: "2.5px solid white",
      padding: "1.1em",
      borderRight: "0px",
    },
    secondSection: {
      padding: "2em 4em",
      backgroundColor: "#FAFAFA",
    },
    rowBoxes: {
      flexFlow: "wrap",
      justifyContent: "center",
    },
    innerBoxOne: {
      margin: "1em 2em",
      width: "40%",
      height: "9em",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "1em",
      // "&:hover": {
      //   border: "2px solid midnightblue"
      // },
    },
    boxTextOne: {
      fontSize: "1.8em",
      fontWeight: "700",
    },
    boxTextTwo: {
      fontSize: "1em",
      marginRight: "0.8em",
      fontWeight: "700",
    },
    fowardIcon: {
      border: "3px solid #2E324B",
      color: "#2E324B",
      borderRadius: "1em",
      fontSize: "1.5em !important",
    },
    filter: {
      display: "none",
    },
    general: {
      position: "relative",
      top: "7em",
      left: "10em",
      width: "20em",
      height: "10em",
      background: "green",
    },
  });