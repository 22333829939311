import { makeStyles } from "@material-ui/core";
import coder from "../../asset/images/coder.png";

export const useStyles = makeStyles((theme) => ({
  helperText: {
    color: "red",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  sectionOne: {
    backgroundImage: `url(${coder})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "40em",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      backgroundPositionX: "right",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "65em",
    },
  },
  sectionOneText: {
    width: "45%",
    fontSize: "2.8em",
    position: "relative",
    top: "5em",
    left: "5em",
    fontFamily: "segoe script",
    [theme.breakpoints.down("sm")]: {
      left: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      left: "1em",
      width: "90%",
      fontSize: "2.3em",
      margin: "2em auto",
      padding: "3em 0",
    },
  },
  sectionTwo: {
    alignItems: "center",
  },
  innerSectionTwo: {
    background: "#fafafa",
    color: "#2E324B",
    width: "80%",
    paddingBottom: "8em",
    position: "relative",
    top: "-10em",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  divider: {
    height: "0.2em",
    width: "40%",
    marginTop: "3.5em",
    marginBottom: "3em",
    backgroundColor: "#063E63",
  },
  leftSide: {
    paddingLeft: "7em",
    paddingRight: "4em",
    paddingTop: "5em",
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2em",
      paddingRight: "1em",
      paddingTop: "5em",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      paddingLeft: "1em",
    },
  },
  leftSideTop: {
    marginBottom: "2em",
  },
  leftSideHeader: {
    fontSize: "3em",
    fontFamily: "geometric",
  },
  leftSideSubHead: {
    fontSize: "2em",
    fontFamily: "Opensanslight",
    fontWeight: "700",
  },
  leftSideText: {
    fontSize: "1.2em",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    "@media only screen and (max-device-width: 1300px)": {
      fontSize: "1.1em",
    },
  },
  leftSideNum: {
    fontSize: "1.5em",
    fontFamily: "avertaDemo",
    fontWeight: "900",
    marginTop: "0.3em",
    marginBottom: "0.5em",
  },
  leftSideDate: {
    fontWeight: "700",
    fontSize: "1.25em",
    marginTop: "0.2em",
  },
  leftSideSentence: {
    marginTop: "0.5em",
    fontSize: "1.1em",
    marginBottom: "0.5em",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    width: "95%",
    "@media only screen and (max-device-width: 1300px)": {
      fontSize: "1em",
    },
  },
  leftSideMail: {
    fontWeight: "700",
    fontSize: "1.2em",
    fontFamily: "Opensansbold",
  },
  rightSide: {
    marginTop: "8%",
    paddingBottom: '2%',
    marginRight: "3.5em",
    width: "45%",
    alignItems: "center",
    background: "white",
    position: "relative",
    top: "4em",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.5em",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "auto",
    },
  },
  textField: {
    margin: "0.8em 0 !important",
    width: "95%",
    borderRadius: "0.35em",
    borderColor: "#2E324B !important",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  notchedOutline: {
    border: "1px solid #2E324B",
  },
  formHeader: {
    marginTop: "1.8em",
    fontSize: "2em",
    marginLeft: "-1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  formButton: {
    marginTop: "0.8em",
    width: "65%",
    borderRadius: "1em",
    fontFamily: "avertaDemo",
    fontSize: "1.1em",
    fontWeight: "400",
    backgroundColor: "#2E324B",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#373c59",
    },
  },
  copyText: {
    cursor: "pointer",
  },
}));
