import { makeStyles } from "@material-ui/core";
import nature from "../../asset/images/nature.png";
import signature from "../../asset/images/signature.png";

export const useStyles = makeStyles((theme) => ({
  secondmainContainer: {
    alignItems: "center",
    display: "flex",
    height: "75vh",
    marginTop: "90px",
    background: "#fafafa",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "110vh",
    },
  },
  secondmain: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "2em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      height: "auto",
    },
  },
  boxItem: {
    width: "50%",
    display: "flex",
    height: "385px",
    "& img": {
      marginLeft: "auto",
      width: "446px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& img": {
        margin: "auto",
        width: "90%",
        height: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& img": {
        margin: "auto",
        width: "100%",
      },
    },
  },
  companyText: {
    width: "600px",
    "& p": {
      padding: "0.5em 0",
      fontSize: "1.2em",
      fontFamily: "AvertaDemo",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "0.3em",
      },
    },
    "& p#firstbold": {
      fontSize: "3em",
      padding: "0",
      fontFamily: "calistItallics",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
    },
  },

  bizText: {
    width: "600px",
    margin: "auto",
    "& p": {
      padding: "0.5em 0",
      fontSize: "1.2em",
      fontFamily: "AvertaDemo",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "0.3em",
      },
    },
    "& h3": {
      fontFamily: "calistItallics",
      textAlign: "center",
      "& #bizAgility::before": {
        content: '"\\a"',
        whiteSpace: "pre",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
    },
  },

  thirdmain: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  thirdsentence: {
    marginLeft: "8.5em",
    width: "fit-content",
    border: "1px solid black",
    margin: "1em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5em",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "5em auto 1em",
    },
  },
  thirdbold: {
    fontSize: "1.5em",
    padding: "0.6em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  thirdtext: {
    fontSize: "1.5em",
  },
  picturemain: {
    display: "flex",
    marginBottom: "4em",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageSection: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    color: "#354054",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },

  middletext: {
    fontSize: "1.4em",
    textAlign: "center",
    padding: "0em 1em",
    fontWeight: "300",
    marginBottom: "0.5em",
  },
  middletextImg: {
    padding: "0em 2em",
  },
  middletextDivider: {
    marginBottom: "40px",
    backgroundColor: "#334350",
  },
  cardText: {
    padding: "0em 2em",
    margin: "2em 0",
  },
  middleEndDivider: {
    display: "flex",
    flexDirection: "column",
    width: "1px",
    "& hr#secondDivider": {
      width: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  circleBox: {
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    border: "2px solid #334350",
    position: "relative",
    left: "-10.5px",
  },
  middleEndDividerActual: {
    backgroundColor: "#334350",
    height: "calc(100% - 25px)",
    width: "1.2px",
  },

  //here

  fourthmain: {
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor: "#2e324b",
    height: "auto",
    padding: "4em",
    gap: "2em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "0",
    },
  },
  solutionEgrImage: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 2em .5em",
      "& img": {
        height: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        height: "auto",
      },
    },
  },
  firstleft: {
    marginTop: "6em",
  },
  firstright: {
    marginTop: "5em",
  },
  secondtextdiv: {
    marginTop: "4em",
  },
  middlesection: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    width: "49%",
    paddingTop: "3em",
    paddingBottom: "1em",
    textAlign: "center",
    justifyContent: "space-between",
  },
  textContent: {
    width: "50%",
  },

  fifthmain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingLeft: "8.5em",
    height: "80vh",
    background: "#fafafa",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "0",
      height: "auto",
    },
  },
  fifthleft: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "3em auto",
    },
  },
  squarePart: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  square: {
    width: "1.2em",
    height: "1.2em",
    margin: "1.5em 0",
    backgroundColor: "#FADD98",
  },
  textdiv: {
    display: "flex",
    flexDirection: "column",
    "& p span#ifThereis::before": {
      whiteSpace: "pre",
      content: '"\\a"',
    },
    "& p span#no::before": {
      whiteSpace: "pre",
      content: '"\\a"',
    },
    "& p span#box": {
      fontSize: "2.1em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "max-content",
      "& p": {
        fontSize: "5em",
      },
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      width: "100%",
      "& p": {
        fontSize: "3.5em",
      },
    },
  },
  textbold: {
    fontSize: "5em",
    fontFamily: "geometric",
    lineHeight: "0.9",
    margin: "auto",
  },
  vertImage: {
    width: "86%",
    position: "relative",
    top: "-127px",
    transform: "translateX(100%)",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  vertLine: {
    margin: "auto 0",
    width: "70%",
    height: "7px",
    backgroundColor: "#2e324b",
  },
  horizontalImage: {
    margin: "auto",
    backgroundColor: "#2e324b",
    width: "7px",
    height: "20em",
    position: "relative",
    top: "-50px",
    left: "37px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textflex: {
    display: "flex",
    flexDirection: "row",
  },

  boxbold: {
    fontSize: "4em",
    lineHeight: "0.9",
  },
  fifthright: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fifthmiddle: {
    width: "90%",
    height: "90%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${nature})`,
    textAlign: "center",
    margin: "auto",
    backgroundSize: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "auto",
    },
  },
  fifthText: {
    paddingTop: "3em",
    margin: "auto",
    "& p": {
      fontFamily: "geometric",
    },

    "& p span::after": {
      content: '"\\A"',
      whiteSpace: "pre",
    },
    "& p span#exactly": {
      fontSize: "2em",
    },
    "& p span#outside": {
      fontSize: "4em",
    },
    "& p#noBox": {
      fontSize: "3em",
      paddingTop: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 0",
    },
  },
  textItems: {
    lineHeight: "1",
  },
  sixthmain: {
    // backgroundImage: `url(${yellowback})`,
    // height: "100vh",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100% 100%",
    // backgroundPosition: "center",
    backgroundColor: "#fadd98",
    width: "100%",
    textAlign: "right",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      height: "auto",
      flexDirection: "column",
    },
  },
  sixthmainArrows: {
    width: "50%",
    height: "30em",
    display: "flex",
    flexDirection: "column",
    margin: "1em 5em 3em 0",
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  sixthmainArrow: {
    display: "flex",
    margin: "1.5em 0",
    "&:nth-of-type(1)": {
      transform: "translate(-50px, -12px)",
    },
    "&:nth-of-type(2)": {
      transform: "translate(90px, 0px)",
      width: "30%",
    },
    "&:nth-of-type(3)": {
      transform: "translate(-660px, -12px) scale(2.5)",
    },
    "&:nth-of-type(4)": {
      transform: "translate(-650px, -12px) scale(2)",
    },
    "&:nth-of-type(5)": {
      transform: "translate(-400px, -12px) scale(2)",
    },
    "&:nth-of-type(6)": {
      transform: "translate(-500px, -12px)",
    },
    [theme.breakpoints.down("sm")]: {
      "&:nth-of-type(1)": {
        transform: "rotate(90deg) translate(11px, -636px)",
        width: "30%",
      },
      "&:nth-of-type(2)": {
        transform: "rotate(90deg) translate(85px, -510px)",
        width: "50%",
      },
      "&:nth-of-type(3)": {
        transform: "rotate(90deg) translate(-11px, -373px) scale(2)",
        width: "40%",
      },
      "&:nth-of-type(4)": {
        transform: "rotate(90deg) translate(-51px, -219px) scale(2)",
        width: "45%",
      },
      "&:nth-of-type(5)": {
        transform: "rotate(90deg) translate(-110px, -103px)",
        width: "40%",
      },
      "&:nth-of-type(6)": {
        transform: "rotate(90deg) translate(-204px, 55px)",
        width: "70%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:nth-of-type(1)": {
        transform: "rotate(90deg) translate(50px, -240px)",
        width: "100%",
      },
      "&:nth-of-type(2)": {
        transform: "rotate(90deg) translate(-7px, -120px)",
        width: "100%",
      },
      "&:nth-of-type(3)": {
        transform: "rotate(90deg) translate(26px, -180px) scale(2)",
        width: "100%",
      },
      "&:nth-of-type(4)": {
        transform: "rotate(90deg) translate(-50px, -60px) scale(2)",
        width: "90%",
      },
      "&:nth-of-type(5)": {
        transform: "rotate(90deg) translate(-110px, -40px)",
        width: "50%",
      },
      "&:nth-of-type(6)": {
        transform: "rotate(90deg) translate(-240px, 55px)",
        width: "100%",
      },
    },
  },
  sixthmainArrowSub: {
    position: "relative",
    height: "10px",
    width: "50px",
    background: "white",
    animation: `a1 1.5s 1 linear`,
    animationFillMode: "forwards",
    "&::before, &::after": {
      content: `""`,
      background: "white",
      position: "absolute",
      height: "8px",
      width: "27px",
    },
    "&::before": {
      right: "-6px",
      bottom: "-6px",
      transform: "rotate(-45deg)",
    },
    "&::after": {
      right: "-6px",
      top: "-6px",
      transform: "rotate(45deg)",
    },
  },
  sixthpart: {
    width: "50%",
    paddingRight: "8em",
    margin: "auto",
    marginRight: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    height: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      margin: "1.5em",
      padding: "0",
      alignItems: "start",
    },
  },
  sixthbold: {
    "& span::after": {
      content: '"\\A"',
      whiteSpace: "pre",
    },
    fontSize: "3.5em",
    lineHeight: "1",
    fontFamily: "bellMtBold",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "3em",
    },
  },
  sixthnormal: {
    marginTop: "2em",
  },
  sixthtext: {
    width: "687px",
    "& p": {
      textIndent: "10px",
      fontSize: "1.4em",
      fontFamily: "avertaDemo",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  seventhpart: {
    backgroundImage: `url(${signature})`,
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },
  seventhmain: {
    height: "80%",
    margin: "auto",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "5em auto",
    },
  },
  moreunderline: {
    height: "0.35rem",
    width: "10rem",
    justifySelf: "center",
    background: "black",
    textAlign: "center",
  },
  sourcepart: {
    "& h2,h3": {
      fontFamily: "calistItallics",
      fontSize: "2.5em",
    },
    "& h3": {
      fontSize: "2em",
    },
    "& p": {
      margin: "1em 0",
      fontSize: "1.25em",
    },
    color: "#2e324b",
  },
}));
