import { createTheme } from "@material-ui/core"
import React from "react";

export const navThemes = {
    navbar:{
        default:{
            light: {
            foreground: "#ffff",
            background: "transparent"
          },
          dark: {
            foreground: "#ffffff",
            background: "#000000"
          }
        },
        mobileDefault:{
          light: {
              foreground: "#ffff",
              background: "#2E324B"
            },
            dark: {
              foreground: "#fffff",
              background: "#2E324B"
            }
      },
        custom:{
          light: {
            foreground: "#fff",
            background: "#2E324B"
          },
          dark: {
            foreground: "#fff",
            background: "#2E324B"
          }
        },
        mobileCustom:{
            light: {
                foreground: "#ffff",
                background: "#2E324B"
              },
              dark: {
                foreground: "#fffff",
                background: "#2E324B"
              }
        },
        learning:{
          light: {
            foreground: "#fff",
            background: "#2E324B"
          },
          dark: {
            foreground: "#fff",
            background: "#2E324B"
          }
        }
    }
}


export const ThemeContext = React.createContext({
    navTheme:navThemes.navbar,
    setTheme:()=>{}
});

export const theme  = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

