import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

import upView from "../asset/videos/upView.mp4";
import Audi2021 from "../asset/images/Audi2021.png";
import cartWheel from "../asset/images/cartWheel.png";
import glassPattern from "../asset/images/glassPattern.png";
import robot_hand from "../asset/images/robotHand.png";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  firstSection: {
    height: "100vh",
    width: "100%",
    color: "white",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.4)",
    justifyContent: "center",
  },
  videoBackground: {
    zIndex: "-1",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    maxWidth: "100%",
  },
  videoText: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      textAlign: "center",
    },
  },
  firstHeader: {
    fontSize: "4.5em",
    lineHeight: "1em",
    fontWeight: "700",
    fontFamily: "geometric",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3.5em",
    },
  },
  firstSentence: {
    fontSize: "1.2em",
    textAlign: "center",
    width: "60%",
    fontWeight: "700",
    fontFamily: "Opensanslight",
    marginTop: "2em",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  query: {
    position: "fixed",
    right: "0%",
    width: "4.5%",
    height: "8%",
    top: "26%",
    padding: "0.4em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: "1",
    border: "2px solid whitesmoke",
    borderRight: "0px",
  },
  secondSection: {
    height: "100vh",
    backgroundColor: "#2E324B",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      height: "100%",
    },
  },
  nonBlankTwo: {
    width: "40%",
    color: "white",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: "auto 5em",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "2em auto",
    },
  },
  blankTwo: {
    height: "100%",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
      height: "70%",
    },
  },
  twoHeader: {
    fontSize: "2em",
    fontWeight: "700",
    marginBottom: "0.5em",
    fontFamily: "Leaguespartanbold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  twoSentence: {
    fontSize: "3vh",
    fontFamily: "Opensanslight",
    fontWeight: "400",
    marginTop: "0.7em",
    marginBottom: "0.7em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em"
    },
  },
  thirdSection: {
    height: "100vh",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "100%",
    },
  },
  empty: {
    width: "20%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  blankThree: {
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  nonBlankThree: {
    width: "40%",
    textAlign: "left",
    margin: "auto 5em",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "2em auto",
    },
  },
  Image: {
    maxWidth: "100%",
    height: "auto",
    objectFit: "cover",
  },
  threeHeader: {
    fontSize: "2em",
    fontFamily: "Leaguespartanbold",
    fontWeight: "100",
    marginBottom: "0.1em",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
    },
  },
  threeSentence: {
    fontSize: "3vh",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    marginTop: "0.7em",
    marginBottom: "0.7em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  dividerMain: {
    backgroundColor: "gray",
    width: "0.15em",
    height: "100%",
    position: "relative",
    left: "100%",
  },
}));

const OtherServices = () => {
  const scrollToSection = (ref) => window.scrollTo(0, ref.current.offsetTop - 90);
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refs = {
    enablingTech: useRef(),
    employeeEval: useRef(),
    OpsEfficiency: useRef(),
    digitalComm: useRef(),
  };
  const location = useLocation();
  useEffect(() => {
    if(location.state?.section){
      scrollToSection(refs[location.state?.section]);
    }
  }, [location, refs]);
  return (
    <Box className={classes.column}>
      <Box className={`${classes.firstSection} ${classes.column}`}>
        <video autoPlay muted loop className={classes.videoBackground}>
          <source src={upView} type="video/mp4" />
        </video>
        <Box className={`${classes.videoText} ${classes.column}`}>
          <Typography className={classes.firstHeader}>Utilizing</Typography>
          <Typography className={classes.firstHeader}>
            The Competence
          </Typography>
          <Typography className={classes.firstSentence}>
            Achieving the highest result by putting together the best thinkers
            can spur extraordinary results
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.secondSection} ${classes.row}`}>
        <Box className={`${classes.empty}`}>
          <Divider className={classes.dividerMain} orientation="vertical" />
        </Box>
        <Box ref={refs.enablingTech} className={`${classes.nonBlankTwo} ${classes.column}`}>
          <Typography className={`${classes.twoHeader}`}>
            Enabling Technology
          </Typography>
          <Typography className={`${classes.twoSentence}`}>
            {
              "Most organizations encounter business complexities due to the paradigm shift from legacy technology to new technologies."
            }
          </Typography>
          <Typography className={`${classes.twoSentence}`}>
            {
              " As enterprises upgrade or change their technologies they must ensure compatibility with the old systems and data formats."
            }
          </Typography>
          <Typography className={`${classes.twoSentence}`}>
            {
              "At ECSCORP, we impress our customers with customized software that enhances robust performance."
            }
          </Typography>
        </Box>
        <Box className={`${classes.blankTwo}`}>
          <img className={classes.Image} src={robot_hand} alt="robot_hand" />
        </Box>
      </Box>
      <Box className={[classes.thirdSection, classes.row]}>
        <Box className={`${classes.empty}`}>
          <Divider className={classes.dividerMain} orientation="vertical" />
        </Box>
        <Box className={`${classes.blankThree}`}>
          <img className={classes.Image} src={cartWheel} alt="cartWheel" />
        </Box>
        <Box ref={refs.employeeEval}   className={`${classes.nonBlankThree}  ${classes.column}`}>
          <Typography className={`${classes.threeHeader}`}>
            Employee Competence Evaluation
          </Typography>
          <Typography className={`${classes.threeSentence}`}>
            {
              "A thorough competency assessment of the workforce will help build a resourceful organization that delivers value deliberately to complement the actualization of your vision."
            }
          </Typography>
          <Typography className={`${classes.threeSentence}`}>
            {
              " Our intervention will drive a precise insight into the skill you already have and what competence is lacking to help the organization achieve a optimum performance."
            }
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.secondSection} ${classes.row}`}>
        <Box className={`${classes.empty}`}>
          <Divider className={classes.dividerMain} orientation="vertical" />
        </Box>
        <Box ref={refs.OpsEfficiency} className={`${classes.nonBlankTwo} ${classes.column}`}>
          <Typography className={`${classes.twoHeader}`}>
            {"Operations & Efficiency"}
          </Typography>
          <Typography className={`${classes.twoSentence}`}>
            {
              "Adopting a productivity mindset can be challenging but the pay off is enormous. With our acquired business insight, we can show you how to do business in a way that improves profitability through efficiency as your continuous growth is our main objective."
            }
          </Typography>
          <Typography className={`${classes.twoSentence}`}>
            {
              "We put the right tool in place to drive organizational effectiveness through knowledge transfer, innovation and process improvement."
            }
          </Typography>
        </Box>
        <Box className={`${classes.blankTwo}`}>
          <img className={classes.Image} src={Audi2021} alt="Audi2021" />
        </Box>
      </Box>
      <Box className={`${classes.thirdSection} ${classes.row}`}>
        <Box className={`${classes.empty}`}>
          <Divider className={classes.dividerMain} orientation="vertical" />{" "}
        </Box>
        <Box className={`${classes.blankThree}`}>
          <img
            className={classes.Image}
            src={glassPattern}
            alt="glass-pattern"
          />
        </Box>
        <Box ref={refs.digitalComm} className={`${classes.nonBlankThree} ${classes.column}`}>
          <Typography className={`${classes.threeHeader}`}>
            Digital Commerce
          </Typography>
          <Typography className={`${classes.threeSentence}`}>
            {
              " The name of the game is vital positioning! The capacity of an organization to decide developing chances and use its important human capital to maximize benefits from these open doors through an e-business procedure is very crucial."
            }
          </Typography>
          <Typography className={`${classes.threeSentence}`}>
            {
              "At ECSCORP, we design digital vision for your business. We connect your business to the digital world and help you realize your vision through exceptional results."
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherServices;
