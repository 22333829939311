export const MenuItems = [
  {
    title: 'Application Service',
    path: '/application-service',
    cName: 'dropdown-link',
  },
  {
    title: 'Business Agility',
    path: '/business-agility',
    cName: 'dropdown-link',
  },
  {
    title: 'Customer Strategy',
    path: 'customer-strategy',
    cName: 'dropdown-link',
  },
  {
    title: 'Digital Commerce',
    path: '/digital-commerce',
    cName: 'dropdown-link',
  },
  {
    title: 'Learning & Engagement',
    path: '/learning',
    cName: 'dropdown-link',
  },
];
