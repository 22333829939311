import { makeStyles } from "@material-ui/core";
import plug from '../../asset/images/plug.png'

export const useStyles = makeStyles((themes)=>({
    column: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    sectionOne: {
      backgroundColor: "#020820",
      color: "white",
      paddingTop: "6em",
      paddingBottom: "5em",
      [themes.breakpoints.down('sm')]:{
        flexDirection:"column",
        padding:"1.5em",
        paddingTop:"90px"
      }
    },
    digitalTextArea: {
      width: "45%",
      marginLeft: "7%",
      marginTop: "8%",
      "@media only screen and (max-device-width: 1080px)": {
        width: "60%",
        marginLeft: "5%",
      },
      [themes.breakpoints.down('sm')]:{
        display:"none",
      }
    },
    createText: {
      fontFamily: "Bell MT",
      paddingLeft: "0.3em",
      fontSize: "3.5em",
      fontWeight: "900",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "2.2em !important",
      },
    },
    digitalCenter: {
      justifyContent: "center",
      position: "relative",
      left: "2.3em",
      top: "-6%",
    },
    digitalTextOne: {
      fontSize: "7.5em",
      marginRight: "0.1em",
      fontFamily: "Bell MT",
      fontWeight: "700",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "4.5em",
      },
    },
    digitalTextTwo: {
      fontSize: "3.2em !important",
      marginLeft: "0em",
      fontFamily: "Bell MT",
      fontWeight: "700",
      position: "relative",
      top: "0.6em",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "2em !important",
      },
    },
    spacedDigital: {
      position: "relative",
      top: "-14%",
      "@media only screen and (max-device-width: 1080px)": {
        top: "-13%",
      },
    },
    forTextOne: {
      fontFamily: "Bell MT",
      fontSize: "3.5em",
      fontWeight: "700",
      position: "relative",
      left: "11%",
      "@media only screen and (min-device-width: 1370px)": {
        left: "13%",
      },
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "2em",
      },
      [themes.breakpoints.down('sm')]:{
        left:"0",
        fontSize: "2.3em",
        marginBottom:"0.75em"
      }
    },
    forTextTwo: {
      fontFamily: "Bell MT",
      fontSize: "3.5em",
      paddingLeft: "1em",
      fontWeight: "700",
      position: "relative",
      left: "12%",
      "@media only screen and (min-device-width: 1370px)": {
        left: "15%",
      },
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "2em",
        left: "18%",
      },
    },
    digitalSentence: {
      fontSize: "1.2em",
      width: "95%",
      fontFamily: "AvertaDemo",
      position: "relative",
      left: "1em",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "0.8em",
      },
      [themes.breakpoints.down('sm')]:{
        fontSize:"0.9em",
        left:"0"
      }
    },
    digitalImage: {
      width: "45%",
      marginLeft: "2.5em",
      "@media only screen and (max-device-width: 1300px)": {
        marginLeft: "2.25em",
      },
      "@media only screen and (max-device-width: 1080px)": {
        width: "50%",
        marginLeft: "0.5em",
      },
      [themes.breakpoints.down("sm")]:{
        width:"100%",
        margin:"0",
        order:"1"
      }
    },
    digitalImageItemOne: {
      width: "80%",
      position: "relative",
      bottom: "-12em",
      left: "-0.2em",
      "@media only screen and (min-device-width: 1370px)": {
        bottom: "-13em",
      },
      "@media only screen and (max-device-width: 1300px)": {
        bottom: "-11.2em",
      },
      "@media only screen and (max-device-width: 1080px)": {
        width: "80%",
        bottom: "-13.1em",
        right: "7em",
      },
      
      [themes.breakpoints.down("sm")]:{
        position:"inherit",
        width:"100%"
      }
    },
    virtualR: {
      "@media only screen and (max-device-width: 1080px)": {
        width: "100%",
      },
    },
    mobileDigitalTextArea:{
      display:"none",
      [themes.breakpoints.down("sm")]:{
        order:"2",
        display:"block"
      }
    },
    sectionTwo: {
      backgroundColor: "#2E324B",
      height: "9em",
      [themes.breakpoints.down("sm")]:{
       height:"4em"
      }
    },
    sectionThree: {
      marginTop: "5em",
      marginBottom: "3em",
      alignItems: "center",
      [themes.breakpoints.down("sm")]:{
        margin:"1.5em"
       }
    },
    miningHeader: {
      fontSize: "3em",
      fontWeight: "700",
      fontFamily: "Bell MT",
      [themes.breakpoints.down("sm")]:{
        lineHeight:"1em",
        fontSize: "2.2em",
        marginBottom:"0.25em"
       }
    },
    miningTextOne: {
      
      width: "100%",
      '& h6':{
      width:"28em",
      textAlign: "center",
      fontSize: "1.1em",
      margin:"auto",
      marginBottom: "2em",
      fontFamily: "AvertaDemo",
      "@media only screen and (max-device-width: 1080px)": {
        width: "70%",
      },
      
      [themes.breakpoints.down("sm")]:{
        width:"100%",
        textAlign:"start",
        fontSize: "1em",
        margin:"0",
      marginBottom: "1em",
       }
      }
    },
    sectionFour: {
      width: "70%",
      position: "relative",
      left: "18%",
      marginBottom: "5em",
      backgroundColor: "rgba(236, 236, 236, 0.08)",
      "@media only screen and (max-device-width: 1080px)": {
        width: "90%",
        left: "5%",
      },
      [themes.breakpoints.down("sm")]:{
        flexDirection:"column",
        left:"0",
        width:"85%",
        margin:"0 1.5em"
       }
    },
    subSectionOne: {
      marginLeft: "1em",
      height: "20em",
      justifyContent: "space-evenly",
      [themes.breakpoints.down("sm")]:{
        justifyContent:"space-around",
        height:"auto"
       }
    },
    subSectionHeader: {
      fontSize: "2em",
      fontFamily: "AvertaDemo",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "1.2em",
      },
    },
    subSectionSentence: {
      fontSize: "0.9em",
      fontFamily: "Opensanslight",
      fontWeight: "700",
      marginLeft: "0.1em",
      width: "90%",
      "@media only screen and (max-device-width: 1080px)": {
        width: "100%",
      },
    },
    subSectionText: {
      marginBottom: "2em",
      marginLeft: "0.5em",
    },
    sectionFive: {
      backgroundColor: "#FADD98",
      height: "30em",
      
    },
    shapedArea: {
      width: "7%",
      justifyContent: "space-between",
      alignItems: "center",
      [themes.breakpoints.down("sm")]:{
        display:"none"
      }
    },
    shaped: {
      backgroundColor: "white",
      width: "100%",
      height: "5em",
    },
    centralContentArea: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      [themes.breakpoints.down('sm')]:{
       flexDirection:"column-reverse"
      }
    },
    centralContent: {
      width: "50%",
      position: "relative",
      top: "40%",
      left: "-18%",
      "@media only screen and (max-device-width: 1080px)": {
        width: "80%",
        left: "-10%",
        top: "18%",
      },
      [themes.breakpoints.down('sm')]:{
        width:"90%",
        position:"inherit",
        padding:"1.5em"
      }
    },
    contentHeader: {
      fontSize: "2.6em",
      fontWeight: "700",
      fontFamily: "Bell MT",
      [themes.breakpoints.down('sm')]:{
        fontSize:"2em"
      }
    },
    contentText: {
      fontSize: "1.1em",
      fontFamily: "Opensanslight",
      fontWeight: "600",
      marginTop: "1em",
      [themes.breakpoints.down('sm')]:{
        fontSize:"1em"
      }
    },
    centralImage: {
      width: "100%",
      position: "relative",
      top: "-27%",
      [themes.breakpoints.down('sm')]:{
        top:"0"
      }
    },
    sectionSix: {
      height: "45em",
      backgroundImage: `url(${plug})`,
      backgroundSize:"100%",
      backgroundRepeat:"no-repeat",
      backgroundColor: "#020820",
      color: "white",
      alignItems: "center",
      justifyContent: "center",
      padding: "0em 4em 0em 4em",
      "@media only screen and (max-device-width: 1080px)": {
        height: "40em",
      },
      [themes.breakpoints.down('sm')]:{
        padding:"0",
        height:"11em",
      justifyContent: "center",
      }
    },
    meetTexts: {
      justifyContent: "center",
      marginTop: "1em",
      [themes.breakpoints.down('sm')]:{
        margin: "0",
      }
    },
    meetTitle: {
      fontFamily: "Bell MT",
      fontSize: "4em",
      fontWeight: "900",
      "@media only screen and (max-device-width: 1080px)": {
        fontSize: "3em",
      },
      [themes.breakpoints.down('sm')]:{
        fontSize: "2em",
        color:"#fadd98",
        letterSpacing:"1.4px"
      }
    },
    meetOne:{
      padding:"1.5em",
      textAlign:"center",
    },
    meetTextOne: {
      margin: "1.2em 1em 0em 0em",
      width: "30%",
      fontFamily: "AvertaDemo",
      fontSize: "1em",
      "@media only screen and (max-device-width: 1080px)": {
        width: "40%",
      },
      [themes.breakpoints.down('sm')]:{
        width:"100%",
        lineHeight:"1.5em"
      }
    },
    meetTextTwo: {
      margin: "1.2em 1em 0em 0.5em",
      position: "relative",
      fontFamily: "Opensans",
      fontSize: "3.5em",
      top: "-1.1em",
      [themes.breakpoints.down('sm')]:{
        fontSize: "1.5em",
        margin:"0",
        position:"initial",
        lineHeight:"1.5em"
      }
    },
    sectionSeven: {
      width: "50%",
      position: "relative",
      left: "28%",
      margin: "7em 0em 7em 0em",
    },
    blankHeader: {
      fontSize: "4em",
      fontWeight: "700",
      fontFamily: "Bell MT",
      lineHeight: "1em",
      marginBottom: "0.4em",
    },
    blankText: {
      fontSize: "1.2em",
      fontFamily: "AvertaDemo",
    },
    sectionNine: {
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "5em",
      paddingBottom: "5em",
      [themes.breakpoints.down('sm')]:{
        flexDirection: "column-reverse",
        padding:"1em 0"
      }
    },
    paintText: {
      width: "40%",
      marginRight: "5em",
      textAlign: "right",
      [themes.breakpoints.down('sm')]:{
        width: "auto",
        padding:"1.5em",
      textAlign: "start",
      margin:"0"
      }

    },
    paintHeader: {
      fontSize: "2.5em",
      fontWeight: "700",
      fontFamily: "Bell MT",
      marginBottom: "0.7em",
      [themes.breakpoints.down('sm')]:{
        fontSize: "2em",
      }
    },
    paintWords: {
      fontSize: "1em",
      fontFamily: "AvertaDemo",
      [themes.breakpoints.down('sm')]:{
        fontSize:"1em",
        }
    },
    paintImage:{
      paddingBottom:"1em",
      [themes.breakpoints.down('sm')]:{
      height:"240px",
      }
    },
    imagePaint:{
      width:"100%",
      
      [themes.breakpoints.down('sm')]:{
        
      objectFit:"contain",
      objectPosition:"50% -171px",
        }
      
    }
  })
  );
  