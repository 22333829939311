export const data = [{
    compName:"solution",
    navText:"Solution Engineering",
    head:'Take the Limits off Your Imagination',
    content:{p1:"As a team of best brains with immense insight and experience in management consulting and technology solutions, we deplore creativity, innovation and collaboration to engineer solutions that result in efficiency, growth and the right ROI for your business.",p2:""}
},
{
    compName:"technology",
    navText:"Technology",
    head:"Building Innovation for Business Process",
    content:{p1:"Digital transformation drives productivity alongside industrial automation. We help businesses harness the digital transformation in the modern workplace to boost agility and productivity while driving digital culture through our portfolio of software solutions."},
},
{
    compName:"operations",
    navText:"Operations",
    head:"People Drive Business Processes -  An Old-age Conundrum",
    content:{p1:"To get a process-driven organization, start with people! An effective business process can only be driven by people. People trigger and enforce changes in a business-process hence the need to ensure a robust process that governs employee management."},
},
{
    compName:"project",
    navText:"Project",
    head:"Achieving More through Synergism",
    content:{p1:"At ECSCORP, we believe in the power of collaboration which helps us achieve and deliver on our various projects, and business interventions thereby causing a direct turnaround in economic growth.",
    }
}
]