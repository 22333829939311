import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import {  Typography } from "@material-ui/core";
import landscape from "../../asset/images/landscape.png";
import video_rec from "../../asset/images/video_rec.png";
import virt from "../../asset/images/virt.png";
import television from "../../asset/images/television.png";
import speaker from "../../asset/images/speaker.png";
import index_f from "../../asset/images/index_f.png";
import paint from "../../asset/images/paint.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useStyles } from "./digitalCommerceStyle";
import useCheckMobileScreen from "../../utils/checkMobile";
import { GetInContact } from "../home/GetInContact";

const digitalTextOne = {
  visible: { scale: 0.2 },
  hidden: {
    scale: 1,
    transition: { type: "tween", duration: 1, delay: "0" },
  },
};
const sectionThree = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 }, delay: "0" },
  hidden: { opacity: 0.5, y: 50 },
};
const subSectionOne = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 }, delay: "0" },
  hidden: { opacity: 0, x: -50 },
};
const subSectionTwo = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 }, delay: "0" },
  hidden: { opacity: 0, x: 50 },
};

const DigitalCommerce = () => {
  const classes = useStyles();
  const refDom = useRef(Array(2))
  const parentRef = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });
  const controls = useAnimation();
  const ismobile = useCheckMobileScreen()

  if(ismobile){
    refDom.current.forEach(i=>parentRef?.current?.appendChild(i))
  }
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);


  return (
    <>
      <Box className={`${classes.sectionOne} ${classes.row}`}>
        <Box className={`${classes.column} ${classes.digitalTextArea}`}>
          <Typography className={`${classes.createText}`} variant="h4">
            {"Creating a stunning"}
          </Typography>
          <Box className={`${classes.digitalCenter} ${classes.row}`}>
            <Typography
              className={`${classes.digitalTextOne}`}
              component={motion.div}
              variants={digitalTextOne}
              animate={controls}
              ref={ref}
              initial="hidden"
              variant="h2"
            >
              digital
            </Typography>
            <Typography className={`${classes.digitalTextTwo}`} variant="h4">
              experience
            </Typography>
          </Box>
          <Box className={`${classes.row} ${classes.spacedDigital}`}>
            <Typography className={`${classes.forTextOne}`} variant="h3">
              for
            </Typography>
            <Typography className={`${classes.forTextTwo}`} variant="h3">
              transformation
            </Typography>
          </Box>
          <Typography className={`${classes.digitalSentence}`} variant="h6">
            {"We help businesses build a profitable growth experience using our creative space to create the future before it exists"}
          </Typography>
        </Box>
        <Box className={classes.mobileDigitalTextArea}>
        <Typography className={`${classes.forTextOne}`} variant="h3">
             {" Creating a stunning digital experience for transformation"}
        </Typography>
        <Typography className={`${classes.digitalSentence}`} variant="h6">
            {"We help businesses build a profitable growth experience using our creative space to create the future before it exists"}
          </Typography>
        </Box>
        <Box className={`${classes.row} ${classes.digitalImage}`}>
          <Box className={`${classes.digitalImageItemOne}`}>
            <img className={`${classes.virtualR}`} src={virt} alt="virtualR" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionTwo}/>
      <Box
        className={`${classes.column} ${classes.sectionThree}`}
        component={motion.div}
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={sectionThree}
      >
        <Typography className={classes.miningHeader}>
         {" Mining Intelligence Using Insight"}
        </Typography>
        <Box className={classes.miningTextOne}>
        <Typography variant="h6">{"Digital innovation is transforming businesses and creating opportunities to exceed customer's expectations."}
        </Typography>
        <Typography variant="h6">
         {"Find out how you can access the real benefits and what digital solutions we can offer your business."}
        </Typography></Box>
  </Box>
      <Box className={`${classes.sectionFour} ${classes.row}`}>
        <Box
          className={`${classes.column} ${classes.subSectionOne}`}
          component={motion.div}
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={subSectionOne}
        >
          <Box className={`${classes.row}`}>
            <Box>
              <img
                className={`${classes.imageIcons}`}
                src={index_f}
                alt="index_finger"
              />
            </Box>
            <Box className={`${classes.subSectionText}`}>
              <Typography
                className={`${classes.subSectionHeader}`}
                variant="h4"
              >
                {"Digital Engagement"}
              </Typography>
              <Typography
                className={`${classes.subSectionSentence}`}
                variant="h6"
              >
                Bringing real customer experience using the digital technology
                framework
              </Typography>
            </Box>
          </Box>
          <Box className={`${classes.row}`}>
            <Box>
              <img
                className={`${classes.imageIcons}`}
                src={landscape}
                alt="landscape"
              />
            </Box>
            <Box className={`${classes.subSectionText}`}>
              <Typography
                className={`${classes.subSectionHeader}`}
                variant="h4"
              >
                UI/UX Design
              </Typography>
              <Typography
                className={`${classes.subSectionSentence}`}
                variant="h6"
              >
                User experience design, bringing design centric approach to user
                interface
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          className={`${classes.column} ${classes.subSectionOne}`}
          component={motion.div}
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={subSectionTwo}
        >
          <Box className={`${classes.row}`}>
            <Box>
              <img
                className={`${classes.imageIcons}`}
                src={video_rec}
                alt="video_recorder"
              />
            </Box>
            <Box className={`${classes.subSectionText}`}>
              <Typography
                className={`${classes.subSectionHeader}`}
                variant="h4"
              >
                Videography
              </Typography>
              <Typography
                className={`${classes.subSectionSentence}`}
                variant="h6"
              >
                {"Creating your story using motion graphics to bring your idea to reality"}
              </Typography>
            </Box>
          </Box>
          <Box className={`${classes.row}`}>
            <Box>
              <img
                className={`${classes.imageIcons}`}
                src={speaker}
                alt="speaker"
              />
            </Box>
            <Box className={`${classes.subSectionText}`}>
              <Typography
                className={`${classes.subSectionHeader}`}
                variant="h4"
              >
                Branding
              </Typography>
              <Typography
                className={`${classes.subSectionSentence}`}
                variant="h6"
              >{"Creating a strong positive perception of your company. Build from root to sky."}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={`${classes.row} ${classes.sectionFive}`}>
        <Box className={`${classes.column} ${classes.shapedArea}`}>
          <Box className={`${classes.shaped} `}/>
          <Box className={`${classes.shaped} `}/>
        </Box>
        <Box className={`${classes.column} ${classes.centralContentArea}`}>
          <Box className={`${classes.column} ${classes.centralContent}`}>
            <Typography className={`${classes.contentHeader}`} variant="h4">
              {"Automating the digital experience"}
            </Typography>
            <Typography className={`${classes.contentText}`} variant="h6">
              {"We have a team of Digital Experts with great insight and technical knowledge on how to design a digital vision for your business. We place your business where the most valuable opportunities are and help you realize your vision quickly and confidently with exceptional results."}
            </Typography>
          </Box>
          <Box className={`${classes.centralImage}`}>
            <img style={{ width: "100%" }} src={television} alt="television" />
          </Box>
        </Box>
        <Box className={`${classes.column} ${classes.shapedArea}`}>
          <Box className={`${classes.shaped} ${classes.areaThree}`}></Box>
          <Box className={`${classes.shaped} ${classes.areaFour}`}></Box>
        </Box>
      </Box>
      <Box className={`${classes.column} ${classes.sectionSix}`}>
        <Typography className={`${classes.meetTitle}`} variant="h2">
          Digital Intelligence
        </Typography>
        <Box className={`${classes.row} ${classes.meetTexts}`}>
          <Typography className={`${classes.meetTextOne}`}
           ref = {element => refDom.current[0] = element }
 variant="h6">{"Digital Innovation is creating digital experience and business opportunities that exceeds customer's expectations."}
          </Typography>
          <Typography className={`${classes.meetTextTwo}`} variant="h3">
            {"meet"}
          </Typography>
          <Typography className={`${classes.meetTextOne}`} 
           ref = {element => refDom.current[1] = element } variant="h6">
            {"Find out how you can access the real benefits and what digital solutions we can offer your business digital technologies can help  transform your business."}
          </Typography>
        </Box>
        <Typography className={`${classes.meetTitle}`} variant="h2">
          {"Collaboration"}
        </Typography>
      </Box>
      <Box ref={parentRef} className={classes.meetOne}></Box>
     
      <Box className={`${classes.row} ${classes.sectionNine}`}>
        <Box className={`${classes.column} ${classes.paintText}`}>
          <Typography className={`${classes.paintHeader}`} variant="h4">
            Purpose driven digital experience
          </Typography>
          <Typography className={`${classes.paintWords}`} variant="h6">{"We place your business where the most valuable opportunities are and help you realize your vision quickly and confidently with exceptional results. We have a team of Digital Experts with great insight and technical knowledge on how to design a digital vision for your business"}
          </Typography>
        </Box>
        <Box className={`${classes.paintImage}`}>
          <img className={`${classes.imagePaint}`} src={paint} alt="paint" />
        </Box>
      </Box>
      
      <GetInContact />
      </>
  );
};

export default DigitalCommerce;
