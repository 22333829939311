export const datas = [{
    img:"headset_outline",
    headerText:"Listen",
    bodyText:"We will be happy to listen to all of your business requirements"
},
{
    img:"pencil",
    headerText:"Create",
    bodyText:"We design an intervention and report on likely impact"
},

{
    img:"excellence",
    headerText:"Delivery",
    bodyText:"We assure a satisfactory delivery on innovation"
},
{
    img:"chinese",
    headerText:"Plan",
    bodyText:" We develop a model that suits your business requirements"
},
]