import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { ThemeContext,navThemes as themes } from "../styles/themes"
import useCheckMobileScreen from "../utils/checkMobile"
import NavBar from "./NavBar"

export const Header = () =>{
    const location = useLocation()
    const [theme,setTheme] = useState(themes.navbar.default)
    const isMobile = useCheckMobileScreen()
    const pathName = ["/customer-strategy","/job-search","/about","/learning","/application-service"]
    const otherPath = ["/home","/featured-insight","/other-services","/business-agility","/"]
  useEffect(()=>{
    if(location.pathname==="/customerstrategy" && isMobile){
        setTheme(themes.navbar.mobileCustom)
    }
   else if(pathName.includes(location.pathname)){
    setTheme(themes.navbar.custom)
    }
    else if(otherPath.includes(location.pathname)){
        setTheme(themes.navbar.default) 
    }
    else {  
        setTheme(themes.navbar.custom)
    }
    // eslint-disable-next-line
},[location.pathname,isMobile]) 
   return ( 
    <header>
        <ThemeContext.Provider value={{navTheme:theme,setTheme:setTheme}}>
          <NavBar />
        </ThemeContext.Provider>
    </header>)
}