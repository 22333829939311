import { makeStyles, Typography } from "@material-ui/core"
import { Contactus } from "../../component/ContactUs"
import taperule from "../../asset/images/taperule.png"

export const GetInContact = ()=>{
    const classes = makeStyles(themes=>({

        GICsection:{
            padding:"1em 0",
            display:"flex",
            background: "#A9BDC7",
            justifyContent:"space-evenly",
        },
        contactLeft:{
            [themes.breakpoints.down("sm")]:{
                display:"none"
            }
        },
        contactRight:{
            '& p:first-child':{
                fontFamily:"calistItallics",
            },
            width:"35em",
            margin:"auto 0",
            '& p span#perf':{
                marginLeft:"0"
            },
            [themes.breakpoints.down("sm")]:{
                margin:"1.5em",
                width:"auto"
            }
        },
        getInTouch:{
            '& #head':{
                fontSize:"2.75em",
                fontWeight:"900",
                fontFamily:"opensansbold",
                textAlign:"start"
            },
            '& #body':{
                fontSize:"1.2em",
                marginLeft:"auto",
                marginRight:"0",
            
            },
        '& span#firstText::after':{
               
                content:'"\\A"',
                whiteSpace:"pre"
               
            },
          
        }, 
        contactUs:{
            display:"flex",
            padding:"1em 0"
        },
        performance:{
            fontFamily:"BellMtBold",
            fontSize:"4em",
            lineHeight:"1",
            width:"8.5em",
            paddingBottom:".25em",
            marginLeft:"auto",
            marginRight:"0px",
            [themes.breakpoints.down("sm")]:{
                width:"auto",
                fontSize:"3em",
            }
        },
        tapeRule:{
            height:"386px"
        }
    
    })
)()
    return(
        <div  className = {classes.GICsection}>
        <div className = {classes.contactLeft}>
            <img src={taperule} className={classes.tapeRule} alt={"tapeRule"}/>
        </div>
        <div className = {classes.contactRight}>
            <Typography  className = {classes.performance}>{"Let us improve your"}<span id="perf"> {"performance"}</span></Typography>
            <div className = {classes.getInTouch}>
            <Typography   id={"head"}>{"Get in Touch"}</Typography>
            <Typography id={"body"}><span id={"firstText"}>{"and find out how your business"}</span><span>{"can benefit from our creative space"}</span></Typography>
            </div>
            <div className={classes.contactUs}>
          <Contactus />
          </div>
        </div>
        </div>
    )
}