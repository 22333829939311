import { Box, Button, Modal, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckMark_green from "../../asset/images/CheckMark_green.png";

export const ThankYou = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 10,
    textAlign: "center",
    borderRadius: "1em",
    p: 4,
    "& h2": {
      fontSize: "2.4em",
      fontWeight: "bolder",
      color: "#4f4f4f",
    },
    "& p": {
      fontSize: "1.2em",
      width: "60%",
      margin: "auto",
      fontWeight: "300",
    },
    "& img": {
      width: "300px",
      height: "255px",
    },
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img src={CheckMark_green} alt={"checkgreen"} />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {"Thank You"}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {"Your message has been received and we will be in contact shortly"}
        </Typography>
        <Link to={"/"}>
          {" "}
          <Button>{"Home"}</Button>
        </Link>
      </Box>
    </Modal>
  );
};
