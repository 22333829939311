import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {MenuItems} from './MenuItems'
import '../styles/Dropdown.css'

const DropDown = () => {
    const[click,setClick] = useState(false)

    const handleClick = () => setClick(!click);

    return (
        <div>
            <ul onClick={handleClick}
            className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
            >
                {MenuItems.map((item,index) =>{
                    return (
                        <li key={index} className='linkpadding'>
                        
                            <Link className={item.cName}
                                to={item.path}
                                onClick={() => setClick(false)} 
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                    
                })}
            </ul>
        </div>
    )
}

export default DropDown
