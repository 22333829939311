import { Button, makeStyles, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ButtonVariants } from "../utils/motion";
import { motion } from "framer-motion";

export const Contactus = ({
  bgColor = "white",
  color = "#2E324B",
  text = "Lets Talk",
}) => {
  const classes = makeStyles({
    linkButton: {
      textDecoration: "none",
      borderRadius: "2.5em",
      position: "relative",
      background: `${bgColor}`,
      cursor: "pointer",
      paddingLeft: "1.2em",
    },
    buttonLink: {
      fontFamily: "AvertaDemo",
      textTransform: "none",
      fontSize: "1em",
      color: `${color}`,
      borderRadius: "1.5em",
    },
    fowardIcon: {
      marginLeft: "0.5em",
      border: `3px solid ${color}`,
      color: `${color}`,
      borderRadius: "1em",
      position: "relative",
      fontSize: "1.5em !important",
    },
  })();
  return (
    <Link
      component={motion.a}
      animate="animate"
      initial="initial"
      variants={ButtonVariants}
      className={`${classes.linkButton}`}
      to="/contact-us"
    >
      <Button
        className={classes.buttonLink}
        endIcon={<ArrowForwardIcon className={classes.fowardIcon} />}
      >
        {text}
      </Button>
    </Link>
  );
};

export const GetInTouch = () => {
  const classes = makeStyles((theme) => ({
    touchpart: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "45%",
      backgroundColor: "#013B4F",
      color: "#fff",
      overflow: "hidden",
    },
    touchmainOne: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      "& p": {
        fontSize: "2.5em",
        fontFamily: "calistItallics",
        lineHeight: "1.1em",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    touchmainTwo: {
      display: "flex",
      borderRadius: "50%",
      flexDirection: "column",
      width: "45%",
    },
    circleTxt: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    resCircle: {
      width: "450px",
      height: "450px",
      borderRadius: "50%",
      background: "#A9BDC7",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        borderRadius: "0",
      },
    },
    touchboldTwo: {
      fontSize: "1.75em",
      textAlign: "center",
      width: "100%",
      fontFamily: "Leaguespartanbold",
      position: "relative",
      color: "#2E324B",
    },
    touchnormal: {
      color: "black",
      paddingTop: "1em",
      paddingBottom: "2em",
      "& p": {
        fontSize: "1.2rem",
      },
    },
  }))();
  return (
    <Box className={classes.touchpart}>
      <Box className={classes.touchmainOne}>
        <Typography className={classes.touchboldOne}>
          {"Would You like To Improve"}
        </Typography>
        <Typography>{" Your Performance?"}</Typography>
      </Box>
      <Box className={classes.touchmainTwo}>
        <div className={classes.resCircle}>
          <div className={classes.circleTxt}>
            <Typography className={classes.touchboldTwo}>
              Get in Touch
            </Typography>
            <div className={classes.touchnormal}>
              <Typography>{"and find out how your business"}</Typography>
              <Typography>{" can benefit from our creative space"}</Typography>
            </div>
            <Contactus />
          </div>
        </div>
      </Box>
    </Box>
  );
};
