import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { ThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackwardIcon from "@material-ui/icons/ArrowBack";

import brownback from "../../asset/images/brownback.png";
import bigblackarrow from "../../asset/images/bigblackarrow.png";
import { data } from "./solutionData";
import useCheckMobileScreen from "../../utils/checkMobile";
import "../../styles/fonts.css";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#000",
    border: `2px solid #fff`,
    cursor: "pointer",
    width:"24px",
    height:"24px"
  },
  solution: {
    position: "relative",
  },
  solutiontext: {
    color: "#fff",
    fontSize: "1.1em",
    fontFamily: "Opensanslight",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4em",
      width: "100%",
      textAlign: "center",
    },
  },
  forwardIcon: {
    color: "white",
  },
  solutionMain: {
    backgroundColor: "black",
    fontFamily: "AvertaDemo",
    paddingLeft: "7.5em",
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      justifyContent: "center",
    },
  },
  solutionItem: {
    display: "flex",
    flexDirection: "column",
    padding: ".1em",
    margin: "2.3em 1em",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
      width: "100%",
      justifyContent: "space-evenly",
      cursor: "none",
      alignItems:"center",
      flexDirection: "row",
    },
  },
  solutionItemSub: {
    display: "flex",
    flexDirection: "row",
    "& p": {
      margin: "0 0.4em",
    },
  },
  pushPart: {
    backgroundImage: `url(${brownback})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    paddingTop: "8em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
  },
  pushMain: {
    backgroundColor: "#fff",
    width: "40%",
    paddingLeft: "8em",
    paddingRight: "4em",
    paddingTop: "3em",
    [theme.breakpoints.down("md")]: {
      padding: "2em",
      justifyContent: "center",
      width: "85%",
      opacity: "0.6",
    },
    [theme.breakpoints.up("laptop")]: {
      width: "65%", 
    }
  },

  learntext: {
    fontWeight: "700",
    fontFamily: "Opensanslight",
    fontSize: "0.6em",
  },
  learnarrow: {
    padding: "0em 0.5em",
  },
  pushBold: {
    fontSize: "2.8em",
    fontWeight: "700",
    lineHeight: "1",
    "@media only screen and (max-device-width: 600px)": {
      fontSize: "1.5em",
    },
  },
  learnMore: {
    display: "flex",
    marginTop: "0.5em",
    textDecoration: "none",
    justifyContent: "left",
    alignItems: "center",
    paddingBottom: "2em",
    fontSize: "1.8em",
    position: "relative",
    color: "#000",
  },
  pushNormal: {
    fontSize: "1.2em",
    paddingTop: "2.4em",
    fontWeight: "600",
    "@media only screen and (max-device-width: 600px)": {
      fontSize: "0.9em",
    },
    //   lineHeight:'1',
  },
  arrow: {
    padding: "0em 1em",
  },
}))

const Opensans = createTheme({
  typography: {
    fontFamily: ["Opensanslight"].join(","),
  },
});

const BellMTBold = createTheme({
  typography: {
    fontFamily: ["BellMTBold"].join(","),
  },
});

export const Solution = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <ThemeProvider theme={Opensans}>
          <ThemeProvider theme={BellMTBold}>
            <Typography className={classes.pushBold}>{data?.head}</Typography>
          </ThemeProvider>
          <ThemeProvider theme={Opensans}>
            <ThemeProvider theme={Opensans}>
              <Typography className={classes.pushNormal}>
                {data?.content.p1}{" "}
              </Typography>
              <Typography className={classes.pushNormal2}>
                {data?.content.p2}
              </Typography>
            </ThemeProvider>
          </ThemeProvider>
        </ThemeProvider>
      </Box>

      <Link to="/about" className={classes.learnMore}>
        <p className={classes.learntext}>Learn more</p>
        <img
          src={bigblackarrow}
          className={classes.learnarrow}
          alt={"big-black-arrow"}
        />
      </Link>
    </>
  );
};

const CustomComponent = (props)=>{
  const { name, handleClick, i, customActive } = props;
  const classes = useStyles()
  return (
    <div
      className={`border-animation ${classes.solutionItem} ${customActive[name]? "border-animation-active":""}`}
      onClick={(e)=>{handleClick(e, name)}}
    >
      <div
        className={`border-animation__inner ${classes.solutionItemSub}`}
      >
        <Typography
          data-name={name}
          className={classes.solutiontext}
        >
          {i.navText}
        </Typography>
        <Avatar className={classes.avatar}>
          <ArrowForwardIcon className={classes.forwardIcon}  fontSize={"small"}/>
        </Avatar>
      </div>
    </div>
  );
}

export const SolutionComponent = () => {
  const isMobile = useCheckMobileScreen();
  const [dataItem, setDataItem] = useState(data[0]);
  const [dataIndex, setDataIndex] = useState(0);
  const [customActive, setCustomActive] = useState(false);
  const innerRef = useRef();
  const handleBackClick = () => {
    dataIndex <= 0
      ? setDataIndex(data.length - 1)
      : setDataIndex(dataIndex - 1);
  };
  const handleForwardClick = () => {
    dataIndex >= data.length - 1
      ? setDataIndex(0)
      : setDataIndex(dataIndex + 1);
      
  };

  const handleClick = (e, name) => {
    const y =
      innerRef.current.getBoundingClientRect().top + window.pageYOffset - 50;
    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
    const datum = data.find(i => (i.compName === name));
    setDataItem(datum);
    setCustomActive({
      [datum.compName]: true,
    });
  };
  const classes = useStyles();
  return (
    <section className={classes.solution}>
      {!isMobile ? (
        <>
          <Box className={classes.solutionMain} ref={innerRef}>
            {data.map((i,index) => {
              return (
                <>
                  <CustomComponent i={i} key={index} name={i.compName} customActive={customActive} handleClick={handleClick}/>
                </>
              );
            })}
          </Box>
          <div className={classes.pushPart}>
            <div className={classes.pushMain}>
              <Solution data={dataItem} />
            </div>
          </div>
        </>
      ) : (
        <>
          <Box className={classes.solutionMain}>
            <div className={classes.solutionItem} data-name={"solution"}>
              <Avatar className={classes.avatar} onClick={handleBackClick}>
                <ArrowBackwardIcon className={classes.forwardIcon} />
              </Avatar>
              <Typography
                data-name={"solution"}
                className={classes.solutiontext}
              >
                {data[dataIndex]["navText"]}
              </Typography>
              <Avatar className={classes.avatar} onClick={handleForwardClick}>
                <ArrowForwardIcon className={classes.forwardIcon} />
              </Avatar>
            </div>
          </Box>
          <div className={classes.pushPart}>
            <div className={classes.pushMain}>
              <Solution data={data[dataIndex]} />
            </div>
          </div>
        </>
      )}
    </section>
  );
};
