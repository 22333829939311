import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((themes) => ({
  jobRoleDetails: {
    marginLeft: "-40px",
    paddingRight: "17px",
    marginTop: "0",
    marginBottom: "0",
    height: "0",
    overflow: "hidden",
    transition: "all 200ms ease-out",
  },
  showDetails: {
    height: "2rem",
    marginBottom: "15px",
  },
  label: {
    display: "block",
  },
}));
export const AccordionItem = ({
  item,
  index,
  ariaExpanded,
  showDetails,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div key={item.id}>
      <dt>
        <Button
          aria-expanded={ariaExpanded}
          aria-controls={`jobrole${index + 1}_desc`}
          onClick={onClick}
          variant={"outlined"}
          className={classes.label}
        >
          <Typography variant={"h5"}>{item.role}</Typography>
          <Typography>{"VIEW JOB REQUIREMENT"}</Typography>
        </Button>
      </dt>
      <dd>
        <p
          id={`jobrole${index + 1}_desc`}
          className={`${classes.jobRoleDetails} ${classes[`${showDetails}`]}`}
        >
          {item.description}
        </p>
      </dd>
    </div>
  );
};
