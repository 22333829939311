import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import Accordion from "../../component/Accordion";
import { jobRoles } from "./Jobs";

const JobSearch = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={`${classes.firstSection} ${classes.column}`}>
        <Box className={`${classes.textBoxFirst} ${classes.column}`}>
          <Typography className={`${classes.sectionOneText} ${classes.row}`}>
            Join our Journey
          </Typography>
          <Typography className={`${classes.sectionOneText} ${classes.row}`}>
            and grab opportunities
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.secondSection} ${classes.column}`}>
        <Accordion jobRoles={jobRoles} />
      </Box>
    </Box>
  );
};

export default JobSearch;
