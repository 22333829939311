import React from "react";
import { Typography, Box, Divider } from "@material-ui/core";
import mouse from "../../asset/images/mouse.png";
import hands from "../../asset/images/hands.png";
import operations from "../../asset/images/operations.png";
import technology from "../../asset/images/technology.png";
import solutionEngineering from "../../asset/images/solutionEngineering.png";
import { useStyles } from "./AboutStyle";
import { Contactus } from "../../component/ContactUs";

const About = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.secondmainContainer}>
        <Box className={classes.secondmain}>
          <div className={classes.boxItem}>
            <img src={mouse} alt={"sec-left-mouse"} />
          </div>
          <div className={classes.boxItem}>
            <div className={classes.companyText}>
              <Typography id={"firstbold"}>{"Our Company"}</Typography>
              <Typography id={"firsttext"}>
                {
                  " We are a team of best thinkers with immense insight and experience in management consulting and technology solution."
                }
              </Typography>
              <Typography>
                {
                  "We use creativity, innovation & collaboration to  engineer solution to provide efficiency, growth & the right return on investment for your business."
                }
              </Typography>
              <Typography>
                {
                  "Our business has continued to grow as we think people, technology & management operation."
                }
              </Typography>
            </div>
          </div>
        </Box>
      </Box>
      <Box className={classes.thirdmain}>
        <div className={classes.thirdsentence}>
          <Typography className={classes.thirdbold}>{"The Focus"}</Typography>
        </div>
        <Box className={classes.picturemain}>
          <div className={classes.imageSection}>
            <Typography className={classes.middletext}>{"People"}</Typography>
            <Divider className={classes.middletextDivider} />

            <img
              src={hands}
              alt={"main-hands"}
              className={classes.middletextImg}
            />

            <Typography className={classes.cardText}>
              {
                "We review with detailed assesments, business concern to forecast the characteristics of the main potential impact"
              }
            </Typography>
          </div>
          <div className={classes.middleEndDivider}>
            <Box className={classes.circleBox} />
            <Divider
              orientation="vertical"
              className={classes.middleEndDividerActual}
            />
          </div>

          <div className={classes.imageSection}>
            <Typography className={classes.middletext}>
              {"Technology"}
            </Typography>
            <Divider className={classes.middletextDivider} />
            <img
              src={technology}
              alt={"sct-tech"}
              className={classes.middletextImg}
            />

            <Typography className={classes.cardText}>
              {
                "Every company needs support especially when it comes to performance improvement, we can help you deliver"
              }
            </Typography>
          </div>
          <div className={classes.middleEndDivider} id={"2"}>
            <Box className={classes.circleBox} />
            <Divider
              orientation="vertical"
              className={classes.middleEndDividerActual}
              id="secondDivider"
            />
          </div>

          <div elevation={0} className={classes.imageSection}>
            <Typography className={classes.middletext}>
              {"Management Operations"}
            </Typography>
            <Divider className={classes.middletextDivider} />
            <img
              src={operations}
              alt={"sct-ops"}
              className={classes.middletextImg}
            />

            <Typography className={classes.cardText}>
              {
                "Every company needs support especially when it comes to performance improvement, we can help you deliver"
              }
            </Typography>
          </div>
        </Box>
      </Box>
      <Box className={classes.fourthmain}>
        <div className={`${classes.boxItem} ${classes.solutionEgrImage}`}>
          <img src={solutionEngineering} alt={"solution-engineering"} />
        </div>
        <div className={classes.boxItem}>
          <div className={classes.bizText} style={{ color: "#FFF" }}>
            <Typography className={classes.firstbold} variant="h3">
              {"Solution Engineering"}
              <span id="bizAgility">{"for Business Agility"}</span>
            </Typography>
            <Typography className={classes.firsttext}>
              {
                "In dealing with business complexities, uncertainties and changes, we adapt the agile approach using creativity, innovations, resilience and responsiveness which leads to better outcomes."
              }
            </Typography>
          </div>
        </div>
      </Box>
      <Box className={classes.fifthmain}>
        <div className={classes.fifthleft}>
          <div className={classes.squarePart}>
            <Box className={classes.square} />
            <Box className={classes.square} />
            <Box className={classes.square} />
          </div>

          <div className={classes.textdiv}>
            <Typography className={classes.textbold}>
              <span id="">{"We think as"}</span>
              <span id="ifThereis">{"if there is"}</span>
              <span id="no">{"no"}</span>
              <span id="box">{"BOX"}</span>
            </Typography>
            <div className={classes.vertImage}>
              <Divider className={classes.vertLine} />
              <span className={classes.square} />
            </div>
            <div className={classes.horizontalImage}>
              <Divider orientation="vertical" flexItem />
              <span className={classes.square} />
            </div>
          </div>
        </div>

        <div className={classes.fifthright}>
          <div className={classes.fifthmiddle}>
            <div className={classes.fifthText}>
              <Typography className={classes.textItems}>
                <span id="exactly">{"EXACTLY"}</span>
                <span id="outside">{"OUTSIDE"}</span>
              </Typography>
              <Typography id="noBox">{"NO BOX"}</Typography>
            </div>
          </div>
        </div>
      </Box>
      <Box className={classes.sixthmain}>
        <div className={classes.sixthmainArrows}>
          {[...new Array(6)].map((a, i) => {
            return (
              <div className={classes.sixthmainArrow} key={i}>
                <div className={classes.sixthmainArrowSub}></div>
              </div>
            );
          })}
        </div>
        <div className={classes.sixthpart}>
          <Typography className={classes.sixthbold}>
            <span>{" Lets' actualize your goals"}</span>
          </Typography>
          <div className={classes.sixthtext}>
            <Typography>
              {
                "As a solution engineering company, we help our clients deliver the best strategic values for their business through insights, planning, and partnership for incessant growth."
              }
            </Typography>
            <Typography>
              {
                "We believe there is a synergy between brands and their consumer,and success in business is about steps ahead of game."
              }
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Contactus bgColor={"#2e324b"} color={"#fff"} />
          </div>
        </div>
      </Box>
      <Box className={classes.seventhpart}>
        <div className={classes.seventhmain}>
          <div className={classes.sourcepart}>
            <Typography variant={"h2"}>
              {"The Source of our Pride,is ecs-ellent"}
            </Typography>
            <Typography>
              {
                "The greatest asset we have are our employees as they play a key role in our success story. This informs why we have created an internal training, learning and mentorship culture for growth and development to unleash creativity and innovations in them."
              }
            </Typography>
            <Typography>
              {
                "Our goal is to build a platform for sustainable growth for our organization, stakeholders and employees by creating a secured and an enabling work environment that helps us fufill our commitment of adding visible value to our clients' business."
              }
            </Typography>
            <Typography>
              {
                "Our belief in partnership and alliances as the secrets of incessant growth and organization success has fostered collaboration between our brand and many outstanding organizations to deliver technology solutions and/or business processes."
              }
            </Typography>
            <Typography>{" We are a team of the best thinkers"}</Typography>
            <div className={classes.moreunderline}></div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default About;
