import React from "react";
import Box from "@material-ui/core/Box";
import { Divider, Typography } from "@material-ui/core";
import { motion } from "framer-motion";

import { datas } from "./iconDatas";
import { useStyles } from "./BusinessAgilityStyle";

import down_arrow from "../../asset/images/down_arrow.png";
import mibcity from "../../asset/images/mibcity.png";
import alarm from "../../asset/images/alarm.svg";
import coveredBulb from "../../asset/images/coveredBulb.svg";
import skateboard from "../../asset/images/skateboard.png";
import slippers from "../../asset/images/slippers.png";
import adept from "../../asset/images/adept.png";
import solve from "../../asset/images/solve.png";
import productivity from "../../asset/images/productivity.png";

const ArrowDownVariants = {
  animate: {
    y: ["25%", "0%"],
    opacity: 1,
    transition: {
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const BusinessAgility = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={`${classes.sectionOne} ${classes.row}`}>
        <Box className={`${classes.column} ${classes.topTextArea}`}>
          <Typography className={`${classes.topHeader}`} variant="h4">
            {"Enhanced efficiency through collaboration."}
          </Typography>
          <Typography className={`${classes.topSentence}`} variant="h6">
            {
              "Gain insight into business, reduce cost and improve profitability through efficiency. We make use of our creative space to help you grow and maximize profit."
            }
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.sectionTwo}`}>
        <Box className={classes.iconContainer}>
          <div className={classes.boxitem}>
            <img src={solve} alt={"solve"} className={classes.imgIcon} />
            <span className={classes.imgText}>{"Adept Approach"}</span>
          </div>
          <div className={classes.boxitem}>
            <img src={adept} alt={"adept"} className={classes.imgIcon} />
            <span className={classes.imgText}>
              {"Solving the problem right"}
            </span>
          </div>
          <div className={classes.boxitem}>
            <img
              src={productivity}
              alt={"productivity"}
              className={classes.imgIcon}
            />
            <span className={classes.imgText}>
              {"Scalable for productivity"}
            </span>
          </div>
        </Box>
      </Box>
      <Box className={`${classes.sectionThree} ${classes.column}`}>
        <Box className={`${classes.row} ${classes.pictureArea}`}>
          <Box className={`${classes.column}  ${classes.pictureTextBox}`}>
            <Box className={`${classes.pictureBox}`}>
              <img
                className={`${classes.pictureImage}`}
                src={mibcity}
                alt="mibcity"
              />
            </Box>
            <Box className={`${classes.pictureText}`}>
              <Typography className={`${classes.pictureHeader}`} variant="h4">
                {"Innovation"}
              </Typography>
              <Typography className={`${classes.pictureSentence}`} variant="h7">
                {
                  "Adopting productivity mindset and a high performance using innovation."
                }
              </Typography>
            </Box>
          </Box>
          <Box className={`${classes.column}  ${classes.pictureTextBox}`}>
            <Box className={`${classes.pictureBox}`}>
              <img
                className={`${classes.pictureImage}`}
                src={skateboard}
                alt="skateboard"
              />
            </Box>
            <Box className={`${classes.pictureText}`}>
              <Typography className={`${classes.pictureHeader}`} variant="h4">
                {"Strategy"}
              </Typography>
              <Typography className={`${classes.pictureSentence}`} variant="h7">
                {
                  "The art of improvement driven by plan using insight and metrics for growth."
                }
              </Typography>
            </Box>
          </Box>
          <Box className={`${classes.column}  ${classes.pictureTextBox}`}>
            <Box className={`${classes.pictureBox}`}>
              <img
                className={`${classes.pictureImage}`}
                src={slippers}
                alt="slippers"
              />
            </Box>
            <Box className={`${classes.pictureText}`}>
              <Typography className={`${classes.pictureHeader}`} variant="h4">
                {" Partnership"}
              </Typography>
              <Typography className={`${classes.pictureSentence}`} variant="h7">
                {
                  " Transforming operations through partnership to enhance business growth."
                }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          className={`${classes.dividerFirst}`}
          orientation="horizontal"
        />
        <Box className={`${classes.column} ${classes.businessText}`}>
          <Typography className={`${classes.businessHeader}`} variant="">
            {
              "Operational tenacity for business transformation"
            }
          </Typography>
          <Typography className={`${classes.businessSentence}`}>
            {
              "Delivering optimum business solutions for business efficiency and developing a strong collaboration with clients has helped and supported our pro-active approach in solving complex business problems."
            }
          </Typography>
        </Box>
      </Box>
      <Box className={`${classes.sectionFour} ${classes.column}`}>
        <Typography className={`${classes.optimumHeader}`} variant="h3">
          {"Achieving Optimum Service"}
        </Typography>
        <Typography className={`${classes.optimumText}`} variant="h6">
          {
            "Adopting a productivity mindset can be challenging but the pay off is enormous."
          }
        </Typography>
        <Box
          component={motion.div}
          animate="animate"
          initial="initial"
          variants={ArrowDownVariants}
        >
          <img
            src={down_arrow}
            className={`${classes.optimumIcon}`}
            alt="down_arrow"
          />
        </Box>
      </Box>
      <Box className={`${classes.pointed_out}`}></Box>
      <Box className={`${classes.sectionFive} `}>
        <Box className={`${classes.row} ${classes.symbolArea}`}>
          {datas.map((i) => {
            return (
              <Box className={`${classes.row} ${classes.symbolSubArea}`}>
                <div className={classes.symbolImage}>
                  <img
                    src={require(`./../../asset/images/${i.img}.png`).default}
                    alt="headset_outline"
                  />
                </div>
                <Box className={`${classes.column} ${classes.symbolText}`}>
                  <Typography
                    className={`${classes.symbolHeader}`}
                    variant="h4"
                  >
                    {i.headerText}
                  </Typography>
                  <Typography
                    className={`${classes.symbolSentence}`}
                    variant="h6"
                  >
                    {i.bodyText}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={`${classes.sectionSix}`}>
        <Box className={`${classes.innerSix} ${classes.column}`}>
          <div className={classes.allText}>
            <Typography className={`${classes.sixHeader}`} variant="h4">
              {"Right on time to market makes a difference"}
            </Typography>
            <Typography className={`${classes.sixText}`} variant="h6">
              {
                "Getting a well prepared business to the market place when due guarantees its success. We identify your goals and help you develop a cutting edge business model that will probably differentiate your company from its competitors."
              }
            </Typography>
          </div>
        </Box>
        <div className={classes.imgContainer}>
          <img src={alarm} alt="alarm" />
        </div>
      </Box>
      <Box
        className={`${classes.sectionSix} ${classes.sectionSixB}`}
        style={{ backgroundColor: "white" }}
      >
        <div className={classes.imgContainer}>
          <img src={coveredBulb} alt={"coveredbulb"} />
        </div>
        <Box
          className={`${classes.innerSix} ${classes.column}`}
          style={{ alignItems: "center" }}
        >
          <div className={classes.allText}>
            <Typography className={`${classes.sixHeader}`} variant="h4">
              {"Using Innovation to improve performance"}
            </Typography>
            <Typography className={`${classes.sixText}`} variant="h6">
              {
                "We analyze the current process hurdles that precludes reaching the desired goal. We develop a plan to close the gap between the current process status and attainment of your desired goal."
              }
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessAgility;
