import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { ArrowDownwardOutlined } from "@material-ui/icons";

import colored_bricks from "../../asset/images/colored_bricks.png";
import half_filled from "../../asset/images/half_filled.png";
import speed_rail from "../../asset/images/speed_rail.png";
import tin_can from "../../asset/images/tin_can.png";
import { useStyles } from "./FeaturedInsightStyle";
import useCheckMobileScreen from "../../utils/checkMobile";

const FeaturedInsight = () => {
  const classes = useStyles();
  const ref = useRef();
  const isMobile = useCheckMobileScreen();

  return (
    <>
      <Box className={`${classes.row}`}>
        <Box className={classes.arrow_light}>
          <NavLink to={"#horizon"}>
            <IconButton
              style={{ border: `3px solid ${isMobile ? "white" : "black"}` }}
            >
              <ArrowDownwardOutlined
                style={{ color: `${isMobile ? "white" : "black"}` }}
              />
            </IconButton>
          </NavLink>
        </Box>
        <Box className={classes.leftMain}>
          <Divider className={classes.dividerMain} orientation="vertical" />
        </Box>
        <Box className={classes.rightMain}>
          <Box
            className={`${classes.firstSection} ${classes.row}`}
            id={"horizon"}
            ref={ref}
          >
            <Box className={classes.textAreaOne}>
              <Typography className={classes.textAreaHeader}>
                {"Set your gaze on the Horizon - Drive the impact."}
              </Typography>
              <Typography className={classes.textAreaSentence}>
                {
                  "The dynamics of the market changes irrespective of the corner you seat. Your ability to manoeuvre the market in terms of timing and meeting current needs can change the game."
                }
              </Typography>
            </Box>
          </Box>
          <Box
            className={`${classes.secondSection} ${classes.row}`}
            id={"mindset"}
          >
            <Box className={`${classes.textAreaSecond} ${classes.column}`}>
              <Box className={`${classes.secondTexts} ${classes.column}`}>
                <Divider
                  className={classes.dividerSubTwo}
                  orientation="horizontal"
                />
                <Typography className={classes.secondTxtHeader}>
                  {"The Proactive Mindset"}
                </Typography>
                <Typography className={classes.secondTxtSentence}>
                  {"Match your responsive thinking with possible market shift, you can anticipate a solution"}
                </Typography>
              </Box>
              <Box className={`${classes.secondImages} ${classes.row}`}>
                <Box className={`${classes.column} ${classes.secImg}`}>
                  <Box>
                    <img src={half_filled} alt="half_filled" className={classes.secImages} />
                  </Box>
                  <Typography className={classes.imageHeader}>
                    {"Customer Care"}
                  </Typography>
                  <Typography className={classes.imageSentence}>
                    {"Driving customer centric business"}
                  </Typography>
                </Box>
                <Box className={clsx(classes.column, classes.secImg)}>
                  <Box>
                    <img src={colored_bricks} alt="colored_bricks"  className={classes.secImages}/>
                  </Box>
                  <Typography className={classes.imageHeader}>
                   {"Insight into the way we work"}
                  </Typography>
                  <Typography className={classes.imageSentence}>
                    {
                      "Speaking a friendly culture and support to reach a high performing team"
                    }
                  </Typography>
                </Box>
                <Box className={clsx(classes.column, classes.secImg)}>
                  <Box>
                    <img src={speed_rail} alt="speed_rail" className={classes.secImages} />
                  </Box>
                  <Typography className={classes.imageHeader}>
                    {"Innovative roadmap"}
                  </Typography>
                  <Typography className={classes.imageSentence}>
                    {
                      "Building an innovative roadmap for efficiency and business value"
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={clsx(classes.thirdSection, classes.column)}
            ref={ref}
            id={"work"}
          >
            <Box className={clsx(classes.thirdTexts, classes.column)}>
              
              <Typography className={classes.thirdTextHead}>
                {"Change in our perspective to work"}
              </Typography>
              <Typography className={classes.thirdTextSentence}>
                {"Is your everyday task driven by your goal? What will make you perform better at work? And why is that your best option?"}
              </Typography>
              <Typography className={classes.thirdTextSentence}>
                {
                  "Your sense of fulfilment should be driven by the passion to achieve a desired result. To change the game, focus on the specifics daily rather than the mundane as long work hours does not equate productivity."
                }
              </Typography>
              <Typography className={classes.thirdTextSentence}>
                {"Think a better solution that poses the best result!"}
              </Typography>
            </Box>
          </Box>
          <Box
            className={`${classes.fourthSection} ${classes.row}`}
            id={"financial-vehicle"}
          >
            <Box className={clsx(classes.column, classes.fourthText)}>
              <Typography className={classes.fourthTextSubHead}>
                {"Change the game"}
              </Typography>
              <Divider
                className={classes.dividerSubThree}
                orientation="horizontal"
              />
              <Typography className={classes.fourthTextHead}>
                {"Think as if there is no box"}
              </Typography>
              <Typography className={classes.fourthSentence}>
                {
                  "Do not save today's idea for tomorrow; it could be obsolete. The concept of savings is for delayed gratification."
                }
              </Typography>
            </Box>
            <Box className={classes.tinCanBox}>
              <img className={classes.tinCan} src={tin_can} alt="tin_can" />
            </Box>
          </Box>
          <Box
            className={`${classes.fifthSection} ${classes.column}`}
            id={"performance-insight"}
          >
            <Box className={`${classes.innerFifth} ${classes.column}`}>
              <Typography className={classes.fifthSubHead}>
                {"Insight into the way we work"}
              </Typography>
              <Typography className={classes.fifthHeader}>
                {"Performance Insight"}
              </Typography>
              <Typography className={classes.fifthSentence}>
                {
                  "The effectiveness of an organization’s performance is driven by People process which involves the culture, attitude, leadership, and approach to creating a smart and excellent operational environment."
                }
              </Typography>
              <Typography className={classes.fifthSentence}>
                {
                  "Companies must innovate and transform from their usual ways of doing business to attract incessant growth. It is critical for organizations to be ready and capable of adapting to changes for business survival and success in a constantly evolving market."
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FeaturedInsight;
