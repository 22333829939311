import { makeStyles } from "@material-ui/core";
import map_sketch from "../../asset/images/map_sketch.png";
export const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  arrow_light: {
    position: "fixed",
    left: "6em",
    bottom: "5em",
    zIndex: "2",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      left: "45%",
      bottom: "2vh",
    },
  },
  leftMain: {
    backgroundColor: "#020820",
  },
  rightMain: {
    width: "100%",
  },

  firstSection: {
    height: "100vh",
    backgroundColor: "#020820",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  dividerMain: {
    backgroundColor: "gray",
    width: "0.15em",
    height: "95.5%",
    marginTop: "9em",
    position: "relative",
    left: "15em",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textAreaOne: {
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
      padding: "5em 2em",
    },
  },
  textAreaHeader: {
    fontSize: "2.75em",
    fontWeight: "500",
    lineHeight: "1.2em",
    width: "75%",
    textAlign: "left",
    fontFamily: "geometric",
    marginBottom: "0.3em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5em",
      lineHeight: "1em",
    },
  },
  textAreaSentence: {
    fontSize: "1.2em",
    width: "85%",

    fontFamily: "avertaDemo",
  },

  secondSection: {
    color: "#020820",
    justifyContent: "end",
    backgroundImage: `url(${map_sketch})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  textAreaSecond: {
    alignItems: "flex-end",
    marginTop: "4em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1em",
    },
  },
  dividerSubTwo: {
    backgroundColor: "#020820",
    width: "15%",
    height: "0.3em",
    position: "relative",
    left: "0em",
  },
  secondTexts: {
    width: "55%",
    marginBottom: "3em",
    marginRight:"7.5em",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2em",
      margin:"auto",
      width: "95%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  secondTxtHeader: {
    fontSize: "2.4em",
    textAlign: "right",
    width: "85%",
    fontFamily: "geometric",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      lineHeight: "1",
      marginBottom: "0.5em",
    },
  },
  secondTxtSentence: {
    fontSize: "1.2em",
    textAlign: "right",
    width: "95%",
    fontFamily: "avertaDemo",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      lineHeight: "1.3",
    },
  },
  secondImages: {
    marginRight:"8em",
    gap:"1em",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin:"auto",
      width: "100%",
    },
  },
  imageHeader: {
    fontSize: "0.8em",
    fontWeight: "700",
    margin: "0.5em 0.5em 0 0.5em",
    fontFamily: "opensansbold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2em",
      fontWeight: "800",
      margin: "1em",
    },
  },
  imageSentence: {
    fontSize: "1em",
    margin: "0 0.5em 1.5em 0.5em",
    fontFamily: "AvertaDemo",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9em",
      fontWeight: "800",
      margin: "0 1em 1.5em 1em",
      lineHeight: "1.2"
    },
  },
  secImg: {
    width: "286px",
    height: "100%",
    backgroundColor: "whitesmoke",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
    },
  },
  secImages: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  thirdSection: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F8FA",
    padding:"2em 0",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "5em",
    }
  },
  thirdTexts: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  thirdTextSubHead: {
    fontSize: "1em",
    marginTop: "1.5em",
    marginBottom: "1em",
    fontWeight: "700",
    fontFamily: "opensansbold",
  },
  thirdTextHead: {
    fontSize: "2.3em",
    marginBottom: "0.2em",
    fontWeight: "700",
    fontFamily: "Leaguespartanbold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  thirdTextSentence: {
    fontSize: "1.2em",
    marginBottom: "1em",
    fontFamily: "AvertaDemo",
  },
  fourthSection: {
    color: "white",
    height: "45em",
    backgroundColor: "black",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      height: "max-content",
    },
  },
  fourthText: {
    backgroundColor: "#020820",
    alignItems: "flex-end",
    width: "50%",
    justifyContent: "center",
    paddingRight: "4em",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
      padding: "5em 1em",
      alignItems: "flex-start",
    },
  },
  fourthTextSubHead: {
    fontSize: "1.5em",
    marginBottom: "1em",
    fontFamily: "opensansbold",
  },
  dividerSubThree: {
    backgroundColor: "white",
    width: "15%",
    height: "0.3em",
    marginBottom: "1.5em",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
    },
  },
  fourthTextHead: {
    fontSize: "3.5em",
    fontFamily: "calistItallics",
    width: "350px",
    margin: "0.5em 0",
    lineHeight: "1",
    color: "#FFD700	",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    }
  },
  fourthSentence: {
    fontSize: "1.2em",
    marginBottom: "1em",
    width: "415px",
    fontFamily: "AvertaDemo",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    }
  },
  buttonFourth: {
    color: "white",
    textTransform: "none",
    border: "2px solid white",
    borderRadius: "1em",
    fontSize: "1.5em",
    marginTop: "3em",
    marginBottom: "6em",
    fontFamily: "AvertaDemo",
  },
  tinCanBox: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto",
    }
  },
  tinCan: {
    width: "100%",
    height: "100%",
  },
  fifthSection: {
    justifyContent: "center",
    alignItems: "center",
    background: "#F6F8FA",
    paddingTop: "4em",
    paddingBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: "1em",
    },
  },
  innerFifth: {
    alignItems: "flex-start",
    width: "55%",
    marginLeft: "5em",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "auto",
      padding: "3em 1em",
    },
  },
  fifthSubHead: {
    fontSize: "1.5em",
    marginBottom: "1em",
    fontFamily: "opensansbold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1em",
    },
  },
  fifthHeader: {
    fontSize: "2.3em",
    marginBottom: "0.5em",
    fontFamily: "Leaguespartanbold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.1em",
    },
  },
  fifthSentence: {
    fontSize: "1.2em",
    marginBottom: "1em",
    fontFamily: "AvertaDemo",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.3em",
    },
  },
}));
