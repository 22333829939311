import React from "react";

import navlogo from "../asset/images/navlogo.png";
import facebook from "../asset/images/facebook.png";
import youtube from "../asset/images/youtube.png";
import twitter from "../asset/images/twitter.png";
import linkedn from "../asset/images/linkedn.png";
import instagram from "../asset/images/instagram.png";


import "../styles/Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-header">
        <img src={navlogo} alt={"footerlogo"} />
        <h4 className="footer-text">Intervention Driven By Agility</h4>
      </div>

      <div className="footer-main">
        <div className="footer-left">
          {/* <img src={navlogo} alt={"footerlogo"} /> */}
        </div>
        <div className="footer-right">
            <ul className="footer-link">
              <li>
                <Link className="linkconnect" to="/">
                  Functions
                </Link>
              </li>

              <li>
                <Link className="linkconnect" to="/contact-us">
                  Connect
                </Link>
              </li>
              <li>
                <Link className="linkconnect" to="/career">
                  Careers
                </Link>
              </li>
              <li>
                <Link className="linkconnect" to="/about">
                  About Us
                </Link>
              </li>
            </ul>
          <div className="footer-icons">
            <span className="follow-us">{"Follow us"}</span>
            <div></div>
            <a href={"https://www.facebook.com/ECSCORP-RESOURCES-806103136186462"} target={"_blank"}  rel="noreferrer"><img src={facebook} alt={"facebook"} /></a>
            <a href={"https://www.youtube.com/channel/UCNoKuohgzVBXgej_8sm7_5Q"}  target={"_blank"}  rel="noreferrer"><img src={youtube} alt={"youtube"} /></a>
            <a href={"https://www.linkedin.com/in/ecscorp-resources-b2909b11a/"}  target={"_blank"}  rel="noreferrer"><img src={linkedn} alt={"linkedln"} /></a>
            <a href={"https://www.instagram.com/ecscorp/"}  target={"_blank"}  rel="noreferrer"><img src={instagram} alt={"instagram"} /></a>
            <a href={"https://twitter.com/ecscorpresource"}  target={"_blank"}  rel="noreferrer"><img src={twitter} alt={"twitter"} /></a>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="footer-lower">
        <p>
          Copyright &#169;{new Date().getFullYear()} &nbsp;{" "}
        </p>
        <div className={"footer-lower-image"}>
            <img src={navlogo} alt={"footerlogo"} />
          </div>
      </div>
    </div>
  );
};

export default Footer;
