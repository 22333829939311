import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  bluepart: {
    background: "#2E324B",
    height: "80vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      paddingTop: "10em",
    },
  },
  firstmain: {
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    position: "relative",
    width: "50%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      left: "0",
    },
  },
  firstmainright: {
    clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
    "& img": {
      maxWidth: "100%",
    },
  },
  firstmainleft: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: "1.5em",
    },
  },
  firstmainbold: {
    fontSize: "3.5em",
    lineHeight: "1.1",
    fontFamily: "calistItallics",
  },
  firstmainnormal: {
    fontSize: "1.2em",
    marginTop: "1em",
    fontFamily: "avertaDemo",
    marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },

  secondmain: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    height: "85vh",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: "1.5em",
      padding: "0",
      height: "100vh",
    },
  },
  secondmainleft: {
    height: "80%",
    width: "45%",
    margin: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& img": {
      height: " 100%",
      width: "70%",
      margin: "auto",
    },
  },
  secondmainright: {
    marginLeft: "0",
    marginRight: "auto",
    width: "40%",
    "& h3": {
      fontFamily: "calistItallics",
    },
    "& p": {
      fontFamily: "avertaDemo",
      fontSize: "1.2em",
      margin: "1em 0",
    },

    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: "0",
    },
  },
  secondmainbold: {
    fontSize: "1.35em !important",
    marginTop: "1em",
    fontFamily: "calistItallics !important",
  },

  thirdmain: {
    width: "100%",
    alignContent: "center",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#2E324B",
    color: "#fff",
    padding: "4em 0",
    fontFamily: "openSans",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2em",
      paddingBottom: "5em",
      height: "auto",
    },
  },
  thirdmainbold: {
    fontFamily: "calistItallics",
    fontSize: "3.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75em",
    },
  },
  thirdmaintext: {
    fontFamily: "AvertaDemo",
    fontSize: "1.2em",
    width: "400px",
    margin: "auto",
  },

  fourthmain: {
    display: "flex",
    fontFamily: "openSans",
    flexDirection: "row",
    padding: "0 5em",
    gap: "2em",
    justifyContent: "center",
    margin: "4em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1em",
      flexDirection: "column",
      height: "auto",
      margin: "0",
      marginTop: "-4em",
    },
  },
  imageSection: {
    display: "flex",
    flexDirection: "column",
    "& h4": {
      fontFamily: "IBMPlexSansBold",
      fontWeight: "700",
      marginLeft: "0.5em",
      marginTop: "0.5em",
    },
    "& p": {
      fontFamily: "AvertaDemo",
      fontSize: ".9em",
      margin: "0.5em",
    },
    width: "252px",
    color: "#354054 !important",
    backgroundColor: "#F1F1F2",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "auto",
      padding: "0em",
      height: "auto",
    },
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  cardtitle: {
    marginTop: "1em",
    fontSize: "1em",
    fontWeight: "700",
  },

  fifthpart: {
    justifyContent: "center",
    alignContent: "center",
    padding: "0 5em",
    margin: "0 auto",
    textAlign: "center",
    background: "#FAFAFA",

    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  fifthmain: {
    height: "70vh",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "auto",
      padding: "0em",
      margin: "7em auto",
    },
  },
  fifthleft: {
    width: "45%",
    margin: "auto",
    display: "flex",
    rowGap: "1.2em",
    columnGap: "1.2em",
    justifyContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
    "& img": {
      width: "200px",
      height: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%",
      textAlign: "start",
    },
  },
  icontext: {
    position: "relative",
    marginTop: "-5em",
    fontSize: "1.35em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-3em",
      fontSize: ".85em",
    },
  },
  fifthicon: {
    [theme.breakpoints.down("sm")]: {
      height: "112px",
      width: "115px",
      textAlign: "start",
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
  },
  fifthright: {
    height: "75vh",
    width: "50%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      width: "100%",
    },
  },
  fifthrightImg: {
    width: "100%",
  },
  fifthrightitems: {
    margin: "0 1.5em",
  },
  underline: {
    height: "0.35rem",
    width: "19rem",
    marginBottom: "1em",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
    },
  },

  textnormal: {
    fontFamily: "Bebas",
    fontSize: "4em",
    lineHeight: "1",
    textAlign: "start",
    color: "#fff",
    width: "8em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5em",
      width: "auto",
    },
  },
  intouchButton: {
    backgroundColor: "black",
  },
}));
